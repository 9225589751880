<script>
import { NAMESPACE, PVC, PV } from '@/shell/config/types';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { PVC_LABELS, ALIAS, GUEST_AGENT_ANNOTATIONS } from '@/pkg/pai/config/labels-annotations';

import { PRODUCT_NAME as PAI } from '~/pkg/pai/config/pai';
import { waitFor } from '@shell/utils/async';

export default {
  name: 'CloneVmModal',
  data() {
    return {
      namespaces: [],
      pvcs:       [],
      loading:    false,
      form:       {
        name:      '',
        namespace: ''
      },
      rules: {
        name: [{
          required: true,
          message:  this.t('pai.edit.placeholder') + this.t('pai.vmset.name'),
          trigger:  'blur'
        }],
        namespace: [{
          required: true,
          message:  this.t('pai.edit.SelectPlaceholder') + this.t('namespace.label'),
          trigger:  'blur'
        }],
      }
    };
  },
  async fetch() {
    this.namespaces = await this.$store.dispatch('cluster/findAll', { type: NAMESPACE });
    this.pvcs = await this.$store.dispatch('cluster/findAll', { type: PVC });
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'cloneVmModal';
    },
    value() {
      return this.$store.state['pai-common'].currentItem;
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else {
        return 'local';
      }
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    onConfirm() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          this.loading = true;
          // 获取该云主机的所有pvc
          const pvcs = this.pvcs.filter((v) => v.labels && v.labels[PVC_LABELS.MOUNT_VM] === this.value.metadata.name);
          const suffix = parseInt(new Date().getTime() / 1000);

          const newVmName = `vm${ suffix }`;

          /**
           *   volumeClaimTemplates:
           *     - metadata:
           *         name: volume-1
           *       spec:
           *         resources:
           *           requests:
           *             storage: 100Gi
           *         storageClassName: yx-test1-image1700121506
           *       status: {}
           *     - metadata:
           *         name: volume-2
           *       spec:
           *         resources:
           *           requests:
           *             storage: 100Gi
           *         storageClassName: longhorn
           *       status: {}
           *   volumes:
           *     - name: pvc-vm1699433710-0-0-volume-1
           *       persistentVolumeClaim:
           *         claimName: pvc-vm1699433710-0-0-volume-1
           */
          // 准备创建新的pv
          // 使用已有磁盘的名字数组
          const existsVolumeNames = [];

          this.value.spec?.volumes?.forEach((item) => {
            if (item?.persistentVolumeClaim?.claimName) {
              existsVolumeNames.push(item?.persistentVolumeClaim?.claimName);
            }
          });
          // existsVolumeNames = ['pvc-vm1699433710-0-0-volume-1']
          /**
           * apiVersion: v1
           * kind: PersistentVolumeClaim
           * metadata:
           *   annotations:
           *     com.tdology.pvc.type: vmosdisk
           *     com.tdology.virt.vmsets: vm1700212312
           *     pv.kubernetes.io/bind-completed: 'yes'
           *     pv.kubernetes.io/bound-by-controller: 'yes'
           *     volume.beta.kubernetes.io/storage-provisioner: driver.longhorn.io
           *     volume.kubernetes.io/storage-provisioner: driver.longhorn.io
           *   creationTimestamp: '2023-11-08T08:55:39Z'
           *   finalizers:
           *     - kubernetes.io/pvc-protection
           *     - provisioner.storage.kubernetes.io/cloning-protection
           *   labels:
           *     com.tdology.pvc.mounto: vm1700212312
           *     com.tdology.pvc.vmos: linux
           *     com.tdology.virt.vmsets: vm1700212312
           *   name: pvc-vm1699433710-0-0-volume-1
           *   namespace: default
           *   resourceVersion: '172254997'
           *   uid: acd2a58a-4705-4b3c-9a11-6180d0c0378f
           * spec:
           *   accessModes:
           *     - ReadWriteOnce
           *   resources:
           *     requests:
           *       storage: 1Gi
           *   storageClassName: cypress-image1695022455
           *   volumeMode: Block
           *   volumeName: pvc-acd2a58a-4705-4b3c-9a11-6180d0c0378f
           * status:
           *   accessModes:
           *     - ReadWriteOnce
           *   capacity:
           *     storage: 1Gi
           *   phase: Bound
           */
          const templateVolumeNames = pvcs.filter((v) => !existsVolumeNames.includes(v.metadata.name)).map((v) => v.metadata.name);

          /**
           * apiVersion: v1
           * kind: PersistentVolumeClaim
           * metadata:
           *   annotations:
           *     com.tdology.pvc.type: datadisk
           *     pv.kubernetes.io/bind-completed: 'yes'
           *     pv.kubernetes.io/bound-by-controller: 'yes'
           *     volume.beta.kubernetes.io/storage-provisioner: driver.longhorn.io
           *     volume.kubernetes.io/storage-provisioner: driver.longhorn.io
           *   creationTimestamp: '2023-11-17T09:12:43Z'
           *   finalizers:
           *     - kubernetes.io/pvc-protection
           *     - provisioner.storage.kubernetes.io/cloning-protection
           *   labels:
           *     com.tdology.pvc.mounto: vm1700212312-0.0
           *     com.tdology.virt.vmsets: vm1700212312
           *   name: pvc-vm1700212312-0-0-volume-2
           *   namespace: default
           *   resourceVersion: '158984681'
           *   uid: e69aa3a2-f140-4a1b-a7c6-1684678a118d
           * spec:
           *   accessModes:
           *     - ReadWriteOnce
           *   resources:
           *     requests:
           *       storage: 100Gi
           *   storageClassName: longhorn
           *   volumeMode: Block
           *   volumeName: pvc-e69aa3a2-f140-4a1b-a7c6-1684678a118d
           * status:
           *   accessModes:
           *     - ReadWriteOnce
           *   capacity:
           *     storage: 100Gi
           *   phase: Bound
           * @type {*[]}
           */
          const newPvcs = [];

          try {
            for (const item of pvcs) {
              const vmName = item.metadata.labels[PVC_LABELS.MOUNT_VM];
              // 循环创建pvc开始
              // 拷贝注解，包括com.tdology.pvc.type
              const annotations = item.metadata.annotations;

              // 移除可能是自动加的注解
              delete annotations['pv.kubernetes.io/bind-completed'];
              delete annotations['pv.kubernetes.io/bound-by-controller'];
              delete annotations['volume.beta.kubernetes.io/storage-provisioner'];
              delete annotations['volume.kubernetes.io/storage-provisioner'];

              const pvc = await this.$store.dispatch(`cluster/create`, {
                type:     PVC,
                metadata: {
                  annotations,
                  // finalizers: item.metadata.finalizers,
                  labels: item.metadata.labels
                },
                spec: item.spec
              });

              delete pvc.spec.volumeName;

              // 设置拷贝来源
              pvc.spec.dataSource = {
                kind: 'PersistentVolumeClaim',
                name: item.metadata.name,
              };

              delete pvc.spec.dataSourceRef;
              // 设置同命名空间
              pvc.metadata.namespace = this.value.metadata.namespace;
              if (templateVolumeNames?.includes(item.metadata.name)) {
                // 新磁盘保证名字被替换
                pvc.metadata.name = item.metadata.name.replace(vmName, newVmName);
              } else {
                // 已有磁盘最后添加个后缀
                pvc.metadata.name = `${ item.metadata.name }-${ suffix }`;
              }
              // 设置绑定到新的主机
              pvc.metadata.labels[PVC_LABELS.MOUNT_VM] = newVmName;

              if (item.metadata.labels[PVC_LABELS.MOUNT_POD]) {
                pvc.metadata.labels[PVC_LABELS.MOUNT_POD] = item.metadata.labels[PVC_LABELS.MOUNT_POD].replace(vmName, newVmName);
              }

              // 保存pvc
              await pvc.save();
              newPvcs.push(pvc);
            }

            const map = {};

            // 等待新创建的pvc有pv并且状态为Bound
            await waitFor(() => {
              return newPvcs.every((pvc) => {
                if (pvc.spec.volumeName) {
                  map[pvc.name] = pvc.spec.volumeName;
                }

                return pvc.spec.volumeName && pvc.status.phase === 'Bound';
              });
            });

            // 跨命名空间下进行复制
            if (this.form.namespace !== this.value.metadata.namespace) {
              for (const item of newPvcs) {
                // 获取并修改刚刚新建的pvc对应的pv
                if (map[item.name]) {
                  const allPvs = await this.$store.dispatch('cluster/findAll', { type: PV, opt: { force: true } });
                  const pv = allPvs.find((v) => map[item.name] === v.metadata.name);

                  // 设置PV的回收策略为Retain
                  const policy = pv.spec.persistentVolumeReclaimPolicy;

                  const data = { spec: { persistentVolumeReclaimPolicy: 'Retain' } };

                  await pv.patch(data, {}, true, true);

                  // 解除老的pvc与新云主机的关系
                  await item.patch({ metadata: { labels: null } }, {}, true, true);

                  // 删除老的pvc
                  await item.remove();

                  // 等待pv状态为Released
                  await waitFor(() => {
                    return pv.status.phase === 'Released';
                  });

                  // 删除claimRef解除跟老的pvc关系
                  const claimRef = { spec: { claimRef: null } };

                  await pv.patch(claimRef, {}, true, true);

                  // 等待pv状态为Available
                  await waitFor(() => {
                    return pv.status.phase === 'Available';
                  });

                  // 创建新的pvc
                  const annotations = item.metadata.annotations;

                  // 移除可能是自动加的注解
                  delete annotations['pv.kubernetes.io/bind-completed'];
                  delete annotations['pv.kubernetes.io/bound-by-controller'];
                  delete annotations['volume.kubernetes.io/storage-provisioner'];

                  const pvc = await this.$store.dispatch(`cluster/create`, {
                    type:     PVC,
                    metadata: {
                      namespace: this.form.namespace, // 设置为新的命名空间
                      name:      item.metadata.name,
                      annotations,
                      // finalizers: item.metadata.finalizers,
                      labels:    item.metadata.labels
                    },
                    spec: item.spec
                  });

                  // 删除复制信息
                  delete pvc.spec.dataSource;
                  delete pvc.spec.dataSourceRef;

                  await pvc.save();

                  // 等待pvc状态为Bound
                  await waitFor(() => {
                    return pvc.status.phase === 'Bound';
                  });

                  // 设置回原来的Policy
                  const policyPatch = { spec: { persistentVolumeReclaimPolicy: policy } };

                  await pv.patch(policyPatch, {}, true, true);
                }
              }
            }
            const initialModel = await this.$store.dispatch(`cluster/create`, {
              type:     PAI_RESOURCES.VMSET,
              metadata: this.value.metadata,
              spec:     this.value.spec
            });

            initialModel.metadata.name = newVmName;
            initialModel.metadata.namespace = this.form.namespace;
            initialModel.setAnnotation(ALIAS, this.form.name);
            this.$delete(initialModel.metadata, 'managedFields');
            this.$delete(initialModel.metadata, 'resourceVersion');
            // 修改云主机的磁盘名称
            if (initialModel.spec.volumes?.length > 0) {
              initialModel.spec.volumes?.forEach((v, i) => {
                initialModel.spec.volumes[i] = {
                  name:                  `${ this.value.spec.volumes[i].name }-${ suffix }`,
                  persistentVolumeClaim: { claimName: `${ this.value.spec.volumes[i].name }-${ suffix }` }
                };
              });
            }
            await initialModel.save();
            // // 重启下虚机
            // await initialModel.powerRestart();
            // 强制更新所有的pvc
            await this.$store.dispatch('cluster/findAll', { type: PVC, opt: { force: true } });
            await this.$message({
              type:    'success',
              message: this.t('pai.vmset.tips.clone.waiting')
            });
            await this.handleClose();
            this.loading = false;
            await this.$router.push({
              name:   `${ PAI }-c-cluster-resource`,
              params: {
                product:  PAI,
                resource: PAI_RESOURCES.VMSET,
                cluster:  this.currentCluster,
              },
            });
          } catch (e) {
            await this.$message({
              type:    'warning',
              message: e.message
            });
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<template>
  <el-dialog
    :title="t('dialog.cloneDialog.title')"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :inline-message="true"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="auto"
    >
      <el-form-item
        :label="t('pai.vmset.name')"
        prop="name"
      >
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item
        :label="t('namespace.selectNamespace')"
        prop="namespace"
      >
        <el-select
          v-model="form.namespace"
          filterable
        >
          <el-option
            v-for="(item) in namespaces.map(v=>v.metadata.name)"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >{{ t('pai.detail.vmset.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
.el-input, .el-select{
  width: 100%;
}
::v-deep .el-form-item__label {
  line-height: unset;
}
</style>
