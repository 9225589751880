import {
  PAI_PLATFORM_SETTING_TYPES,
  PAI_PLATFORM_SETTING,
  PAI_PLATFORM_USER,
} from './types';
import {
  MANAGEMENT,
  NORMAN,
} from '@shell/config/types';
import { uniq } from '@shell/utils/array';
import {
  AGE,
  GROUP_NAME,
  GROUP_ROLE_NAME,
  NAME as HEADER_NAME,
  NAMESPACE,
  RBAC_BUILTIN, RBAC_DEFAULT,
  SIMPLE_NAME,
  STATE,
} from '@shell/config/table-headers';
import dayjs from 'dayjs';

export const PRODUCT_NAME = 'platform';

const USERS_VIRTUAL_TYPE = 'users';
const ROLES_VIRTUAL_TYPE = 'roles';

export const PLATFORM_PRODUCT_TYPES = [
  PAI_PLATFORM_SETTING_TYPES.LICENSE,
  PAI_PLATFORM_SETTING_TYPES.MACHINE,
  PAI_PLATFORM_SETTING_TYPES.GATEWAY,
  MANAGEMENT.USER,
  NORMAN.SPOOFED.GROUP_PRINCIPAL,
];

export function init($plugin, store) {
  const {
    product,
    basicType,
    weightType,
    configureType,
    componentForType,
    headers,
    mapType,
    spoofedType,
    virtualType,
    weightIconGroup
  } = $plugin.DSL(store, PRODUCT_NAME);

  const typeStoreMap = {};

  PLATFORM_PRODUCT_TYPES.forEach((item) => {
    if (item !== PAI_PLATFORM_SETTING_TYPES.MACHINE) {
      typeStoreMap[item] = 'cluster';
    }
  });
  product({
    inStore:               'management',
    removable:             false,
    showNamespaceFilter:   true,
    typeStoreMap,
    hideKubeShell:         true,
    hideKubeConfig:        true,
    showClusterSwitcher:   true,
    hideCopyConfig:        true,
    hideNamespaceLocation: true,
    to:                    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_PLATFORM_SETTING_TYPES.LICENSE,
        cluster:  'local'
      },
    },
  });

  // 平台管理

  basicType([
    PAI_PLATFORM_SETTING_TYPES.GATEWAY,
    PAI_PLATFORM_SETTING_TYPES.MACHINE,
    PAI_PLATFORM_SETTING_TYPES.LICENSE,
  ], PAI_PLATFORM_SETTING);

  weightIconGroup(PAI_PLATFORM_SETTING, 99, true, 'gear');

  virtualType({
    group:               PAI_PLATFORM_SETTING,
    name:                PAI_PLATFORM_SETTING_TYPES.GATEWAY,
    ifHaveType:          PAI_PLATFORM_SETTING_TYPES.GATEWAY,
    showNamespaceFilter: false,
    labelKey:            'pai.cluster.liveit100.com.gateway',
    weight:              97,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_PLATFORM_SETTING_TYPES.GATEWAY,
        cluster:  'local'
      },
    },
  });
  virtualType({
    group:               PAI_PLATFORM_SETTING,
    name:                PAI_PLATFORM_SETTING_TYPES.MACHINE,
    ifHaveType:          PAI_PLATFORM_SETTING_TYPES.MACHINE,
    showNamespaceFilter: false,
    namespaced:          false,
    labelKey:            'pai.cluster.liveit100.com.machine',
    weight:              98,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_PLATFORM_SETTING_TYPES.MACHINE,
        cluster:  'local'
      },
    },
  });
  virtualType({
    group:               PAI_PLATFORM_SETTING,
    name:                PAI_PLATFORM_SETTING_TYPES.LICENSE,
    ifHaveType:          PAI_PLATFORM_SETTING_TYPES.LICENSE,
    showNamespaceFilter: false,
    namespaced:          false,
    labelKey:            'pai.cluster.liveit100.com.license',
    weight:              99,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_PLATFORM_SETTING_TYPES.LICENSE,
        cluster:  'local'
      },
    },
  });

  configureType(PAI_PLATFORM_SETTING_TYPES.LICENSE, {
    isCreatable: false,
    isEditable:  true,
  });

  // 用户管理

  basicType([
    'config',
    USERS_VIRTUAL_TYPE,
    NORMAN.SPOOFED.GROUP_PRINCIPAL,
    ROLES_VIRTUAL_TYPE,
  ], PAI_PLATFORM_USER);

  weightIconGroup(PAI_PLATFORM_USER, 98, true, 'groups');

  virtualType({
    group:      PAI_PLATFORM_USER,
    labelKey:   'auth.config.label',
    namespaced: false,
    name:       'config',
    weight:     -1,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-auths-config`,
      params: {
        cluster: 'local',
        product: PRODUCT_NAME,
      },
    },
    ifHaveType: MANAGEMENT.AUTH_CONFIG,
  });

  virtualType({
    group:      PAI_PLATFORM_USER,
    labelKey:   'typeLabel."management.cattle.io.user"',
    name:       USERS_VIRTUAL_TYPE,
    ifHaveType: MANAGEMENT.USER,
    namespaced: false,
    weight:     103,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        cluster:  'local',
        product:  PRODUCT_NAME,
        resource: MANAGEMENT.USER,
      },
    },
  });
  configureType(MANAGEMENT.USER, { showListMasthead: false });

  spoofedType({
    labelKey:          'typeLabel."group.principal"',
    type:              NORMAN.SPOOFED.GROUP_PRINCIPAL,
    weight:            101,
    ifHaveType:        MANAGEMENT.GLOBAL_ROLE_BINDING,
    collectionMethods: [],
    schemas:           [
      {
        id:                NORMAN.SPOOFED.GROUP_PRINCIPAL,
        type:              'schema',
        collectionMethods: [],
        resourceFields:    {},
      },
    ],
    route: {
      group:  PAI_PLATFORM_USER,
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        cluster:  'local',
        product:  PRODUCT_NAME,
        resource: NORMAN.SPOOFED.GROUP_PRINCIPAL,
      },
    },
    exact:        false,
    getInstances: async() => {
      // Determine if the user can get fetch global roles & global role bindings. If not there's not much point in showing the table
      const canFetchGlobalRoles = !!store.getters[`management/canList`](MANAGEMENT.GLOBAL_ROLE);
      const canFetchGlobalRoleBindings = !!store.getters[`management/canList`](MANAGEMENT.GLOBAL_ROLE_BINDING);

      if (!canFetchGlobalRoles || !canFetchGlobalRoleBindings) {
        return [];
      }

      // Ensure we upfront load principals (saves making individual requests later)
      await store.dispatch('rancher/findAll', {
        type: NORMAN.PRINCIPAL,
        opt:  { url: '/v3/principals' },
      });

      // getInstances should return a list of principals that have global bindings.
      // It would be easier to just filter principals from above by those with bindings...
      // .. however the principals list from above is NOT complete and misses some that have bindings (seen when using AD)
      // So flip the logic and fetch any principal that's missing from the principal list

      const globalRoleBindings = await store.dispatch('management/findAll', {
        type: MANAGEMENT.GLOBAL_ROLE_BINDING,
        opt:  { force: true },
      });

      const uniquePrincipalIds = uniq(globalRoleBindings
        .filter((grb) => !!grb.groupPrincipalName)
        .map((grb) => grb.groupPrincipalName),
      );

      const allPrincipalsP = uniquePrincipalIds
        .map(async(pId) => {
          // Guard against principals that aren't retrievable (bindings to principals from previous auth providers)
          try {
            return await store.dispatch('rancher/find', {
              type: NORMAN.PRINCIPAL,
              opt:  { url: `/v3/principals/${ encodeURIComponent(pId) }` },
              id:   pId,
            });
          } catch (e) {
            console.warn(`Failed to fetch Principal with id: '${ pId }'`, e); // eslint-disable-line no-console
          }
        });

      const allPrincipals = await Promise.all(allPrincipalsP);

      return allPrincipals
        .filter((p) => !!p)
        .map((p) => ({
          ...p,
          type: NORMAN.SPOOFED.GROUP_PRINCIPAL,
        }));
    },
  });
  configureType(NORMAN.SPOOFED.GROUP_PRINCIPAL, {
    isCreatable:      false,
    showAge:          false,
    showState:        false,
    isRemovable:      false,
    showListMasthead: false,
  });
  // Use labelFor... so lookup succeeds with .'s in path.... and end result is 'trimmed' as per other entries
  mapType(NORMAN.SPOOFED.GROUP_PRINCIPAL, store.getters['type-map/labelFor']({ id: NORMAN.SPOOFED.GROUP_PRINCIPAL }, 2));
  weightType(NORMAN.SPOOFED.GROUP_PRINCIPAL, 101, true);

  virtualType({
    group:      PAI_PLATFORM_USER,
    labelKey:   'rbac.roletemplate.label',
    namespaced: false,
    name:       ROLES_VIRTUAL_TYPE,
    weight:     102,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-auths-roles`,
      params: {
        cluster: 'local',
        product: PRODUCT_NAME,
      },
    },
    // There are two resource types shown on this page, MANAGEMENT.GLOBAL_ROLE and MANAGEMENT.ROLE_TEMPLATE
    // If there user can't see ROLE_TEMPLATE, they definitely can't see GLOBAL_ROLE
    ifHaveType: MANAGEMENT.ROLE_TEMPLATE,
  });

  configureType(MANAGEMENT.AUTH_CONFIG, {
    isCreatable: false,
    isRemovable: false,
    showAge:     false,
    location:    null,
  });

  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/github`, 'auth/github');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/openldap`, 'auth/ldap/index');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/freeipa`, 'auth/ldap/index');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/activedirectory`, 'auth/ldap/index');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/ping`, 'auth/saml');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/shibboleth`, 'auth/saml');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/okta`, 'auth/saml');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/keycloak`, 'auth/saml');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/adfs`, 'auth/saml');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/googleoauth`, 'auth/googleoauth');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/azuread`, 'auth/azuread');
  componentForType(`${ MANAGEMENT.AUTH_CONFIG }/keycloakoidc`, 'auth/oidc');

  headers(NORMAN.SPOOFED.GROUP_PRINCIPAL, [
    GROUP_NAME,
    GROUP_ROLE_NAME,
  ]);

  // A lot of the built in roles have nicer names returned by nameDisplay. In both tables we want to show both nicer and base names
  const DISPLAY_NAME = {
    ...HEADER_NAME,
    name:     'displayName',
    labelKey: 'tableHeaders.nameDisplay',
  };

  headers(MANAGEMENT.GLOBAL_ROLE, [
    STATE,
    DISPLAY_NAME,
    SIMPLE_NAME,
    RBAC_BUILTIN,
    {
      ...RBAC_DEFAULT,
      labelKey: 'tableHeaders.authRoles.globalDefault',
    },
    AGE,
  ]);

  headers(MANAGEMENT.ROLE_TEMPLATE, [
    STATE,
    DISPLAY_NAME,
    SIMPLE_NAME,
    RBAC_BUILTIN,
    RBAC_DEFAULT,
    AGE,
  ]);
  headers(PAI_PLATFORM_SETTING_TYPES.LICENSE, [
    STATE,
    HEADER_NAME,
    {
      name:     'user',
      labelKey: 'pai.edit.license.user',
      value:    `$[spec][user]`,
      align:    'center',
    },
    NAMESPACE,
    {
      name:     'limitsCpu',
      labelKey: 'pai.edit.license.pcpu',
      getValue: (row) => `${ row.spec?.limits?.cpu }/${ row.spec?.limits?.pcpu ? row.spec?.limits?.pcpu : '-' }`,
      sort:     `$[spec][limits][cpu]`,
      width:    120,
      align:    'center',
    },
    {
      name:     'limitsRam',
      labelKey: 'tableHeaders.ram',
      value:    `$[spec][limits][memory]`,
      sort:     `$[spec][limits][memory]`,
      width:    110,
      align:    'center',
    },
    {
      name:      'usedCPU',
      labelKey:  'pai.edit.license.usedCPU',
      getValue:  (row) => row.cpuUsagePercentage,
      formatter: 'PercentageBar',
      width:     120,
      sort:      'cpuUsage',
      search:    false,
      align:     'left',
    },
    {
      name:      'usedRAM',
      labelKey:  'pai.edit.license.usedRAM',
      getValue:  (row) => row.ramUsagePercentage,
      formatter: 'PercentageBar',
      width:     120,
      sort:      'ramUsage',
      search:    false,
      align:     'left',
    },
    {
      name:     'realtime',
      labelKey: 'pai.edit.workload.container.realtime',
      value:    `realtime`,
      align:    'center',
    },
    {
      name:     'loadbalancer',
      labelKey: 'pai.edit.license.loadbalancer',
      value:    `$[spec][loadbalancer]`,
      align:    'center',
    },
    {
      name:     'valid',
      labelKey: 'pai.edit.license.valid',
      value:    `$[status][valid]`,
      sort:     `$[status][valid]`,
      align:    'center',
    },
    {
      name:     'expireDate',
      labelKey: 'pai.edit.license.expireDate',
      value:    `$[spec][expired]`,
      getValue: (row) => row?.spec?.expired ? dayjs(row?.spec?.expired * 1000)?.format('YYYY-MM-DD') : '-',
      sort:     `$[spec][expired]`,
      width:    100,
      align:    'center',
    },
  ]);

  headers(PAI_PLATFORM_SETTING_TYPES.MACHINE, [
    STATE,
    HEADER_NAME,
    {
      name:          'alias',
      labelKey:      'tableHeaders.alias',
      value:         `$['metadata']['annotations']['picloud/alias']`,
      getValue:      (row) => row.metadata?.annotations?.['picloud/alias'],
      sort:          ['aliasSort'],
      formatter:     'LinkDetail',
      canBeVariable: true,
    },
    NAMESPACE,
    {
      name:     'cluster',
      labelKey: 'tableHeaders.clusters',
      value:    `$[metadata][labels][com.tdology.gateway]`,
      sort:     `$[metadata][labels][com.tdology.gateway]`,
    },
    {
      name:     'architecture',
      labelKey: 'tableHeaders.architecture',
      value:    `$[spec][arch]`,
      sort:     `$[spec][arch]`,
    },
    {
      name:     'os',
      labelKey: 'pai.detail.vmset.system',
      value:    `$[spec][os]`,
      sort:     `$[spec][os]`,
    },
    {
      name:  'ip',
      label: 'IP',
      value: `$[spec][ip]`,
      sort:  `$[spec][ip]`,
    },
    {
      name:     'cpu',
      labelKey: 'tableHeaders.cpu',
      value:    `$[status][resource][cpu]`,
      sort:     `$[status][resource][cpu]`,
      align:    'center',
    },
    {
      name:     'ram',
      labelKey: 'tableHeaders.ram',
      value:    `$[status][resource][memory]`,
      sort:     `$[status][resource][memory]`,
    },
    {
      name:      'realtime',
      labelKey:  'pai.edit.workload.container.realtimenum',
      formatter: 'RealtimeContainer',
      align:     'center',
    },
    {
      name:     'bootmode',
      labelKey: 'pai.edit.machine.bootMode',
      value:    `$[status][mode]`,
      sort:     `$[status][mode]`,
    },
    {
      name:     'message',
      labelKey: 'tableHeaders.message',
      value:    `$[status][msg]`,
      sort:     `$[status][msg]`,
    },
    AGE,
  ]);

  headers(PAI_PLATFORM_SETTING_TYPES.GATEWAY, [
    STATE,
    HEADER_NAME,
    NAMESPACE,
    {
      name:     'addr',
      labelKey: 'pai.edit.gateway.address',
      value:    `$[spec][addr]`,
      sort:     `$[spec][addr]`,
    },
    {
      name:     'user',
      labelKey: 'pai.edit.gateway.user',
      value:    `$[spec][user]`,
      sort:     `$[spec][user]`,
    },
    {
      name:     'message',
      labelKey: 'tableHeaders.message',
      value:    `$[status][msg]`,
      sort:     `$[status][msg]`,
    },
    AGE,
  ]);
}
