import SteveModel from '@shell/plugins/steve/steve-class';
import PlatformLocationMixin from '~/pkg/pai/mixins/platformLocation';
import { PRODUCT_NAME } from '~/pkg/pai/config/platform';
import { _EDIT, _UNFLAG, AS, MODE } from '@shell/config/query-params';
import { MACHINE_ANNOTATIONS } from '~/pkg/pai/config/labels-annotations';
import { Message } from 'element-ui';

export default class extends PlatformLocationMixin(SteveModel) {
  get details() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      return [
        {
          label:   this.t('tableHeaders.clusters'),
          content: this.metadata.labels['com.tdology.gateway'],
        },
        {
          label:   'IP',
          content: this.spec.ip,
        },
        {
          label:   this.t('pai.detail.vmset.system'),
          content: this.spec.os,
        },
      ];
    }

    return super.details;
  }

  goToEdit(moreQuery = {}) {
    if (this.annotations && this.annotations[MACHINE_ANNOTATIONS.MAIN_NIC]) {
      const location = this.detailLocation;

      location.query = {
        ...location.query,
        [MODE]: _EDIT,
        [AS]:   _UNFLAG,
        ...moreQuery
      };

      this.currentRouter().push(location);
    } else {
      Message.warning(this.t('pai.edit.machine.noEdit'));
    }
  }
}
