<script>
import {
  SCHEMA, POD, NORMAN, EVENT, CATALOG
} from '@/shell/config/types';
import {
  PVC_LABELS,
  COOKIES_LABELS,
  GUEST_AGENT_ANNOTATIONS,
  RUST_DESK_ANNOTATIONS,
} from '@/pkg/pai/config/labels-annotations';
import { clone } from '@/shell/utils/object';
import { createYaml } from '@/shell/utils/create-yaml';
import OverView from './tabs/Overview.vue';
import Monitor from './tabs/Monitor.vue';
import DiskManagement from './tabs/DiskManagement.vue';
import BackUpManagement from './tabs/BackUpManagement.vue';
import RestoreRecords from './tabs/RestoreRecords.vue';
import SnapshotManagement from './tabs/SnapshotManagement.vue';
import NetworkManagement from './tabs/NetworkManagement.vue';
import EventLog from './tabs/EventLog.vue';
import Conditions from './tabs/Conditions.vue';
import { mapGetters } from 'vuex';
import DeleteVmModal from '../../components/DeleteVmModal';
import CloneVmModal from '../../components/CloneVmModal';
import { VM_POWER_STATES_ENUM } from '../../plugins/pai-resource-class';
import { PRODUCT_NAME as PAI } from '../../config/pai';
import { _EDIT } from '@/shell/config/query-params';
import { COMMAND_HASH_MPA } from '../../config/settings';
import { PAI_CATALOG, PAI_RESOURCES } from '../../config/types';
import CountGauge from '@/shell/components/CountGauge.vue';
import {
  GUEST_COMMANDS,
  sendCommandsToPodFirstContainerWithCallback,
  shutdownPod,
} from '../../utils/guest-command';
import { base64Decode, base64Encode } from '@shell/utils/crypto';
import { randomStr } from '@shell/utils/string';
import { RUST_DESK_URL, WINDOW_OPEN_PARAMS } from '~/pkg/pai/utils';

const tabs = ['overview', 'monitor', 'diskManagement', 'snapshotManagement', 'networkManagement', 'eventLog', 'conditions', 'backUpManagement', 'restoreRecords'];

const backUpYaml = {
  apiVersion: 'velero.io/v1',
  kind:       'Backup',
  metadata:   {
    annotations: { createby: '' },
    labels:      { 'velero.io/storage-location': 'default' },
    name:        '',
    namespace:   'velero',
  },

  spec: {
    csiSnapshotTimeout:       '30m0s',
    defaultVolumesToFsBackup: false,
    includedNamespaces:       '',
    labelSelector:            { matchLabels: { [PVC_LABELS.MOUNT_VM]: '' } },
    storageLocation:          'default',
    ttl:                      '800h0m0s',
    volumeSnapshotLocations:  ['default'],
  },
};

const checkBackup = (rule, value, callback) => {
  if (!value) {
    return callback(new Error(this.t('pai.detail.vmset.enterName')));
  } else if (/[A-Z]/g.test(value)) {
    return callback(new Error(this.t('pai.detail.vmset.duplicateNames')));
  }
};

export default {
  layout:     'pai/default',
  components: {
    OverView,
    Monitor,
    DiskManagement,
    BackUpManagement,
    RestoreRecords,
    SnapshotManagement,
    NetworkManagement,
    EventLog,
    Conditions,
    DeleteVmModal,
    CloneVmModal,
    CountGauge,
  },
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    // 当时时间戳
    const time = new Date().valueOf();

    const info = this?.value?.status?.instances;
    const podList = [];

    for (const key in info) {
      podList.push({ name: key, ...info[key] });
    }
    let currentPod;

    if (podList.length > 0) {
      currentPod = podList[0];
    } else {
      currentPod = {
        name:   '',
        node:   '-',
        status: '-',
      };
    }

    return {
      currentPod,
      activeName:          'overview',
      podActiveName:       '',
      backUpDialogVisible: false,
      form:                { name: `backup-vm-${ this.value.metadata.name }-${ time }` },
      backUpYaml,
      backUpsData:         [],
      rules:               {
        name: [
          {
            validator: checkBackup,
            trigger:   'blur',
          },
        ],
      },
      selectWay:                   'new',
      detailFlag:                  true,
      pods:                        [],
      cookieDesk:                  true,
      guestInstalled:              false,
      checkingGuestInstalled:      false,
      checkingGuestInstalledTime:  0,
      rustDeskId:                  null,
      gettingRustDeskId:           false,
      gettingRustDeskIdTime:       0,
      rustDeskPassword:            null,
      settingRustDeskPassword:     false,
      settingRustDeskPasswordTime: 0,
      destroyed:                   false,
    };
  },
  async fetch() {
    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.BACKUP)) {
      this.backUpsData = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.BACKUP });
    }
    const hash = this.$route.hash ? this.$route.hash.substring(1) : 'overview';

    // 从别的页面可能带过不存在的hash，这里过滤一下
    this.activeName = tabs.includes(hash) ? hash : 'overview';
    if (this.activeName !== 'backUpManagement') {
      if (this.pods.length > 0) {
        this.podActiveName = this.pods[0].name;
      } else if (this.value.instances.length > 0) {
        this.podActiveName = this.value.instances[0].name;
      }
    } else {
      this.podActiveName = 'backUpManagement';
      this.detailFlag = false;
    }

    try {
      this.pods = await this.getPods();
    } catch (e) {
      console.log(e);
    }
    if (this.enableCloudDesk) {
      const isRancher = await this.$store.getters['isRancher'];

      if (isRancher) {
        await this.$store.dispatch('cluster/findAll', { type: CATALOG.APP });
      } else {
        await this.$store.dispatch('cluster/findAll', { type: PAI_CATALOG.HELM_CHART });
      }
    }
  },
  mounted() {
    this.checkAndCache();
    this.$nextTick(() => {
      const tabs = this.$refs.podLinetabs?.$children[0].$refs;
      const backupData = this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.BACKUP);

      if (!backupData) {
        if (tabs) {
          tabs.tabs[tabs.tabs.length - 2].style.display = 'none';
        }
      }
      const restoreData = this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.RESTORE);

      if (!restoreData) {
        if (tabs) {
          tabs.tabs[tabs.tabs.length - 1].style.display = 'none';
        }
      }
    });
    // 原生云桌面cookie条件
    this.cookieDesk = this.$cookies.get(COOKIES_LABELS.XDESK, { parseJSON: false });
  },
  destroyed() {
    this.destroyed = true;
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    enableCloudDesk() {
      return process.env.VUE_APP_CLOUD_DESK_ENABLE === 'true';
    },
    installedCloudDeskApp() {
      if (!this.enableCloudDesk) {
        return false;
      }
      if (this.isRancher) {
        return !!this.$store.getters['cluster/all'](CATALOG.APP).find((i) => i.name === 'clouddesk');
      } else {
        return !!this.$store.getters['cluster/all'](PAI_CATALOG.HELM_CHART).find((i) => i.name === 'clouddesk');
      }
    },
    showInstanceGaugeCircles() {
      const instanceGauges = Object.values(this.value.instanceGauges);
      const total = this.value.instances.length;

      return !instanceGauges.find((pg) => pg.count === total);
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else {
        return 'local';
      }
    },
    podList() {
      const podList = [];
      const info = this?.value?.status?.instances;

      for (const key in info) {
        podList.push({ name: key, ...info[key] });
      }

      return podList;
    },
    podOptions() {
      return this.podList.map((v) => {
        return {
          label:  v.name,
          value:  v.name,
          status: v.status,
        };
      });
    },
    isPowerOn() {
      return this.value.spec.power && this.value.spec.power === 'On';
    },

    deleteVmModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'deleteVmModal';
    },

    cloneVmModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'cloneVmModal';
    },
    isRunning() {
      return this.currentPod.status === VM_POWER_STATES_ENUM.Running;
    },
    isRustDeskEnable() {
      return this.isRunning && this.rustDeskId && this.rustDeskPassword;
    },
    disableSafeOff() {
      return !this.isRunning || !this.guestInstalled;
    },
    principal() {
      return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
    }
  },
  watch: {
    podList: {
      async handler(nue) {
        if (nue.length > 0) {
          this.currentPod = nue[0];
        } else {
          this.currentPod = {
            name:   '',
            node:   '-',
            status: '-',
          };
        }
      },
      deep:      true,
      immediate: true,
    },
    currentPod: {
      handler(nue) {
        this.checkAndCache();
      },
    },
    'value.status.instances': {
      handler(nue) {
        this.checkAndCache();
      },
      deep: true,
    },
    guestInstalled: {
      handler(nue) {
        if (nue) {
          this.getRustDeskId();
          this.setRustDeskPassword();
        }
      },
    }
  },
  methods: {
    checkAndCache() {
      this.guestInstalled = false;
      this.rustDeskId = null;
      this.rustDeskPassword = null;
      this.$store.dispatch('cluster/find', {
        type: POD,
        id:   `${ this.value.namespace }/${ this.currentPod.name }`,
      }).then((pod) => {
        this.guestInstalled = pod.annotations[GUEST_AGENT_ANNOTATIONS.INSTALL] === 'true';
        this.rustDeskId = pod.annotations[RUST_DESK_ANNOTATIONS.ID];
        this.rustDeskPassword = base64Decode(pod.annotations[RUST_DESK_ANNOTATIONS.PW]);
      });
      if (this.installedCloudDeskApp) {
        this.checkGuestInstalled();
      }
    },
    checkGuestInstalled() {
      if (!this.destroyed && this.currentPod && this.isRunning) {
        this.$store.dispatch('cluster/find', {
          type: POD,
          id:   `${ this.value.namespace }/${ this.currentPod.name }`,
        }).then((pod) => {
          if (pod.annotations[GUEST_AGENT_ANNOTATIONS.INSTALL] === undefined) {
            // 避免重复检查
            if (!this.checkingGuestInstalled) {
              this.checkingGuestInstalled = true;
              sendCommandsToPodFirstContainerWithCallback(this.currentCluster, pod, GUEST_COMMANDS.ping, (result) => {
                if (result.success && result.data && result.data['return']) {
                  pod.setAnnotation(GUEST_AGENT_ANNOTATIONS.INSTALL, true);
                  this.guestInstalled = true;
                  const data = { metadata: { annotations: { [GUEST_AGENT_ANNOTATIONS.INSTALL]: 'true' } } };

                  pod.patch(data, {}, true, true);
                  this.checkingGuestInstalled = false;
                }
                if (!result.success) {
                  this.checkingGuestInstalled = false;
                  // 失败后重试20次
                  if (this.checkingGuestInstalledTime < 20) {
                    this.checkGuestInstalled();
                    this.checkingGuestInstalledTime++;
                  }
                }
              });
            }
          } else {
            this.guestInstalled = pod.annotations[GUEST_AGENT_ANNOTATIONS.INSTALL] === 'true';
          }
        });
      }
    },
    getRustDeskId() {
      if (!this.destroyed && this.guestInstalled && this.currentPod && this.isRunning) {
        this.$store.dispatch('cluster/find', {
          type: POD,
          id:   `${ this.value.namespace }/${ this.currentPod.name }`,
        }).then((pod) => {
          if (!pod.annotations[RUST_DESK_ANNOTATIONS.ID]) {
            // 避免重复获取
            if (!this.gettingRustDeskId) {
              this.gettingRustDeskId = true;
              sendCommandsToPodFirstContainerWithCallback(this.currentCluster, pod, GUEST_COMMANDS.rustDesk.getId, (result) => {
                if (result.success && result.data && result.data['return'] && result.data['return']['out-data']) {
                  this.rustDeskId = base64Decode(result.data['return']['out-data']);
                  pod.setAnnotation(RUST_DESK_ANNOTATIONS.ID, this.rustDeskId);
                  const data = { metadata: { annotations: { [RUST_DESK_ANNOTATIONS.ID]: this.rustDeskId } } };

                  pod.patch(data, {}, true, true);
                  this.gettingRustDeskId = false;
                }
                if (!result.success) {
                  this.gettingRustDeskId = false;
                  // 失败后重试5次
                  if (this.gettingRustDeskIdTime < 5) {
                    this.getRustDeskId();
                    this.gettingRustDeskIdTime++;
                  }
                }
              });
            }
          } else {
            this.rustDeskId = pod.annotations[RUST_DESK_ANNOTATIONS.ID];
          }
        });
      }
    },
    setRustDeskPassword() {
      if (!this.destroyed && this.guestInstalled && this.currentPod && this.isRunning) {
        this.$store.dispatch('cluster/find', {
          type: POD,
          id:   `${ this.value.namespace }/${ this.currentPod.name }`,
        }).then((pod) => {
          if (!pod.annotations[RUST_DESK_ANNOTATIONS.PW]) {
            // 避免重复设置
            if (!this.settingRustDeskPassword) {
              this.settingRustDeskPassword = true;
              const password = randomStr();

              sendCommandsToPodFirstContainerWithCallback(this.currentCluster, pod, [...GUEST_COMMANDS.rustDesk.setPassword, password], (result) => {
                if (result.success && result.data && result.data['return'] && result.data['return']['out-data'] && result.data['return']['out-data'] === 'RG9uZSEK') {
                  this.rustDeskPassword = password;
                  const encodedPw = base64Encode(this.rustDeskPassword);

                  pod.setAnnotation(RUST_DESK_ANNOTATIONS.PW, encodedPw);
                  const data = { metadata: { annotations: { [RUST_DESK_ANNOTATIONS.PW]: encodedPw } } };

                  pod.patch(data, {}, true, true);
                  this.settingRustDeskPassword = false;
                }
                if (!result.success) {
                  this.settingRustDeskPassword = false;
                  // 失败后重试5次
                  if (this.settingRustDeskPasswordTime < 5) {
                    this.setRustDeskPassword();
                    this.settingRustDeskPasswordTime++;
                  }
                }
              });
            }
          } else {
            this.rustDeskPassword = base64Decode(pod.annotations[RUST_DESK_ANNOTATIONS.PW]);
          }
        });
      }
    },
    onActionConsole() {
      this.value.onOpenNoVnc(this.currentPod.name);
    },
    onOpenCloudDesk() {
      const podIP = this.pods?.find((i) => i?.status?.podIP).status?.podIP;

      if (this.value?.spec?.os === 'windows') {
        window.open(`http://localhost:8000/pages/rdp.html?v=${ podIP }&sec=tls`);
      } else {
        window.open(`http://localhost:8000/pages/rdp.html?v=${ podIP }&sec=rdp`);
      }
    },
    onOpenRustDesk() {
      window.open(`${ RUST_DESK_URL }?id=${ this.rustDeskId }&pw=${ this.rustDeskPassword }`, this.rustDeskId, WINDOW_OPEN_PARAMS);
    },
    success() {
      this.$message({
        type:    'success',
        message: `${ this.t('pai.labels.success') }`,
      });
    },
    onActionPower(state) {
      // 开关机
      this.$confirm(state === 'Off' ? this.t('pai.labels.powerOffTips') : this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning',
      })
        .then(async() => {
          this.activeName = 'eventLog';
          // 重置重试次数
          this.checkingGuestInstalledTime = 0;
          this.gettingRustDeskIdTime = 0;
          this.settingRustDeskPasswordTime = 0;
          try {
            if (state === 'restart') {
              let safeOffSucceed = false;

              if (this.guestInstalled) {
                for (const pod of this.pods) {
                  if (pod.status !== VM_POWER_STATES_ENUM.Succeeded) {
                    try {
                      await shutdownPod(this.currentCluster, pod);
                      safeOffSucceed = true;
                    } catch (e) {
                      console.error(e);
                      safeOffSucceed = false;
                    }
                  }
                }
                try {
                  await this.value.waitForInstancesStatus(VM_POWER_STATES_ENUM.Succeeded);
                  safeOffSucceed = true;
                } catch (e) {
                  console.error(e);
                  safeOffSucceed = false;
                }
              }

              if (safeOffSucceed) {
                await this.value.powerOn();
                this.success();
              } else {
                this.$confirm(this.t('pai.labels.powerOffTips'), this.t('pai.labels.tip'), {
                  confirmButtonText: this.t('pai.detail.vmset.confirm'),
                  cancelButtonText:  this.t('pai.detail.vmset.cancel'),
                  type:              'warning',
                })
                  .then(async() => {
                    await this.value.powerRestart();
                    this.success();
                  });
              }
            } else if (state === 'safeOff') {
              if (this.guestInstalled) {
                for (const pod of this.pods) {
                  if (pod.status !== VM_POWER_STATES_ENUM.Succeeded) {
                    try {
                      await shutdownPod(this.currentCluster, pod);
                    } catch (e) {
                      console.error(e);
                    }
                  }
                }
              }
              try {
                await this.value.waitForInstancesStatus(VM_POWER_STATES_ENUM.Succeeded);
                this.$message({
                  type:    'success',
                  message: `${ this.t('pai.labels.success') }`,
                });
              } catch (e) {
                this.$confirm(this.t('pai.detail.vmset.unShutdownPod'), this.t('pai.labels.tip'), {
                  confirmButtonText: this.t('pai.detail.vmset.openConsole'),
                  cancelButtonText:  this.t('pai.detail.vmset.cancel'),
                  type:              'warning',
                }).then(() => {
                  this.value.onOpenShell(this.currentPod.name);
                });
              }
            } else {
              this.powerState = state;
              await this.value.power(state);
              this.$message({
                type:    'success',
                message: `${ this.t('pai.labels.success') }`,
              });
            }
          } catch (e) {
            this.$message({
              type:    'warning',
              message: e.message,
            });
          }
        })
        .catch(() => {
        });
    },
    handleCommand(command) {
      if (command === 'edit') {
        this.value.forceFetch();
        const location = {
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            id:        this.value.name,
            product:   PAI,
            resource:  PAI_RESOURCES.VMSET,
            cluster:   this.currentCluster,
            namespace: this.value.namespace,
          },
          query: { mode: _EDIT },
        };

        this.$router.push(location);
      } else if (command === 'clone') {
        this.value.goToCloneVM();
      } else if (command === 'image') {
        this.value.goToMakeImage();
      } else if (command === 'delete') {
        this.$store.dispatch('pai-common/updateState', {
          currentModal: 'deleteVmModal',
          currentItem:  this.value,
        });
      } else {
        // 此处暂时都跳转至虚机详情页，具体的页面跳转和功能后续实现
        this.$router.push({
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            product:   PAI,
            cluster:   this.currentCluster,
            resource:  PAI_RESOURCES.VMSET,
            namespace: this.value.metadata.namespace,
            id:        this.value.metadata.name,
          },
          hash: COMMAND_HASH_MPA[command],
        });
      }
    },
    async onBackUp() {
      // 备份
      if (/[A-Z]/g.test(this.form.name)) {
        this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

        return;
      }
      const backUpSchema = this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.BACKUP);

      this.backUpDialogVisible = false;
      const opt = {
        method:  'post',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        url:  `v1/${ PAI_RESOURCES.BACKUP }`,
      };

      this.backUpYaml.metadata.annotations.createby = this.principal?.loginName;
      this.backUpYaml.metadata.name = this.form.name;
      this.backUpYaml.spec.includedNamespaces = [
        this.value.metadata.namespace,
      ];
      this.backUpYaml.spec.labelSelector.matchLabels[
        'com.tdology.virt.vmsets'
      ] = this.value.metadata.name;

      opt.data = this.toYaml(this.backUpYaml);

      await backUpSchema.$ctx.dispatch('request', opt);
    },
    toYaml(value) {
      // yaml备份格式
      const schemas = this.$store.getters[`cluster/all`](SCHEMA);
      const clonedResource = clone(value);

      return createYaml(schemas, PAI_RESOURCES.BACKUP, clonedResource);
    },
    changePod(value) {
      // 切换pod
      this.currentPod = this.podList.find((v) => v.name === value);
    },
    tabClick(pane, ev) {
      const index = location.href.indexOf('#');

      location.href = `${ location.href.substring(
        0,
        index === -1 ? location.href.length : index,
      ) }#${ pane.name }`;
    },
    changePodTab(tab) {
      const index = location.href.indexOf('#');

      if (
        tab['_props'].name === 'backUpManagement' ||
          tab['_props'].name === 'restoreRecords'
      ) {
        this.detailFlag = false;
        location.href = `${ location.href.substring(
          0,
          index === -1 ? location.href.length : index,
        ) }#${ tab['_props'].name }`;
      } else {
        this.detailFlag = true;
        this.activeName = 'overview';
        this.currentPod = this.podList.find(
          (v) => v.name === tab['_props'].name,
        );
        location.href = `${ location.href.substring(
          0,
          index === -1 ? location.href.length : index,
        ) }#overview`;
      }
    },
    onEditVm(command) {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-id`,
        params: {
          cluster:  this.$route.params.cluster,
          product:  PAI,
          resource: PAI_RESOURCES.VMSET,
          id:       this.value.id,
        },
        query: { mode: _EDIT },
        hash:  COMMAND_HASH_MPA[command],
      });
    },
    async getPods() {
      const pods = [];

      if (this.podList && this.podList.length > 0) {
        for (const pod of this.podList) {
          try {
            const podObj = await this.$store.dispatch('cluster/find', {
              type: POD,
              id:   `${ this.value.namespace }/${ pod.name }`,
            });

            pods.push(podObj);
          } catch (e) {
            console.error(e);
          }
        }
      }

      return pods;
    },
  },
};
</script>

<template>
  <div class="vmDetailPages">
    <el-card class="top">
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex">
          <CountGauge
            v-for="(group, key) in value.instanceGauges"
            :key="key"
            :total="value.instances.length"
            :useful="group.count || 0"
            :graphical="showInstanceGaugeCircles"
            :primary-color-var="`--sizzle-${group.color}`"
            :name="key"
          />
        </div>
        <div style="margin-left: 20px; align-self: center">
          <button
            v-if="isPowerOn"
            class="btn role-primary"
            :disabled="disableSafeOff"
            @click="onActionPower('safeOff')"
          >
            {{ t('pai.detail.vmset.safePowerOff') }}
          </button>
          <button
            v-if="isPowerOn"
            class="btn role-primary"
            @click="onActionPower('Off')"
          >
            {{ t('pai.detail.vmset.powerOff') }}
          </button>
          <button
            v-else
            class="btn role-primary"
            style=""
            @click="onActionPower('On')"
          >
            {{ t('pai.detail.vmset.powerOn') }}
          </button>
          <button
            v-if="isPowerOn"
            class="btn role-primary"
            @click="onActionPower('restart')"
          >
            {{ t('pai.detail.vmset.powerRestart') }}
          </button>
          <el-button
            class="btn role-primary"
            @click="onEditVm('storage')"
          >
            <i class="el-icon-plus" />
            {{ t('pai.detail.vmset.tab.diskManagement.addDisk') }}
          </el-button>
          <el-button
            class="btn role-primary"
            style="margin-left: 0px"
            @click="onEditVm('network')"
          >
            <i class="el-icon-plus" />
            {{ t('pai.detail.vmset.tab.networkManagement.addNetwork') }}
          </el-button>
          <button
            class="btn role-primary"
            @click="handleCommand('image')"
          >
            <i class="el-icon-cloudy" />{{ t('pai.detail.vmset.makeMirror') }}
          </button>
          <button
            class="btn role-primary"
            @click="handleCommand('clone')"
          >
            {{ t('pai.detail.vmset.copy') }}
          </button>
          <button
            class="btn role-primary"
            @click="handleCommand('edit')"
          >
            {{ t('pai.detail.vmset.edit') }}
          </button>
          <button
            class="btn role-secondary"
            @click="handleCommand('delete')"
          >
            {{ t('pai.detail.vmset.delete') }}
          </button>
        </div>
      </div>
    </el-card>
    <el-tabs
      ref="podLinetabs"
      v-model="podActiveName"
      type="card"
      @tab-click="changePodTab"
    >
      <el-tab-pane
        v-for="item in podOptions"
        :key="item.value"
        :label="item.label"
        :name="item.label"
        :active-name="podActiveName"
      >
        <span
          slot="label"
        ><i
           :style="{
             display: 'block',
             width: '9px',
             height: '9px',
             marginLeft: '0 9px',
             background:
               item.status === 'Succeeded'
                 ? '#81B337'
                 : item.status === 'Pending'
                   ? '#4095E5'
                   : item.status === 'Running'
                     ? '#A2EF4D'
                     : item.status === 'Stopped'
                       ? '#BEBEBE'
                       : '#C85352',
             borderRadius: '50%',
             position: 'absolute',
             left: '4px',
             top: '17px',
           }"
         />
          {{ item.label }}</span>
      </el-tab-pane>
      <el-tab-pane
        :label="t('pai.detail.vmset.tab.backUpManagement.backUpManagement')"
        name="backUpManagement"
        :active-name="podActiveName"
      >
        <BackUpManagement :value="value" />
      </el-tab-pane>
      <el-tab-pane
        :label="t('pai.detail.vmset.tab.restoreRecords.restoreRecords')"
        name="restoreRecords"
        :active-name="podActiveName"
      >
        <RestoreRecords
          :value="value"
        />
      </el-tab-pane>
    </el-tabs>
    <div style="position: relative">
      <el-tabs
        v-if="detailFlag"
        v-model="activeName"
        class="tabs"
        @tab-click="tabClick"
      >
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.overview.overview')"
          name="overview"
        >
          <OverView
            :value="value"
            :pod="currentPod"
            :active-name="activeName"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.monitor.monitor')"
          name="monitor"
        >
          <Monitor
            :pod-name="currentPod.name"
            :active-name="activeName"
            :value="value"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.diskManagement.diskManagement')"
          name="diskManagement"
        >
          <DiskManagement
            :value="value"
            :pod-name="currentPod.name"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.snapshotManagement.snapshotManagement')"
          name="snapshotManagement"
        >
          <SnapshotManagement
            :value="value"
            :pod-name="currentPod.name"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.networkManagement.networkManagement')"
          name="networkManagement"
        >
          <NetworkManagement
            :value="value"
            :pod-name="currentPod.name"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.eventLog.eventLog')"
          name="eventLog"
        >
          <EventLog
            :value="value"
            :pod-name="currentPod.name"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.conditions.conditions')"
          name="conditions"
        >
          <Conditions
            :value="value"
            :pod-name="currentPod.name"
          />
        </el-tab-pane>
      </el-tabs>
      <div v-if="detailFlag">
        <div style="position: absolute; right:10px; top:5px; background-color:transparent; border: none; outline:none; box-shadow:none;">
          <button
            v-if="isRunning"
            style="float:right"
            @click="onActionConsole"
          >
            <img
              src="../../assets/images/home/vm-console.svg"
              alt=""
            >
            <a style="margin: -4px 0 0 4px; text-decoration:none">πVnc</a>
          </button>
          <button
            v-else
            style="float:right"
            disabled="true"
          >
            <img
              src="../../assets/images/home/disable-console.svg"
              alt=""
            >
            <span style="margin: -4px 0 0 4px; color: #ccc">πVnc</span>
          </button>
          <span v-if="installedCloudDeskApp">
            <button
              v-if="isRustDeskEnable"
              style="float:right"
              @click="onOpenRustDesk"
            >
              <img
                src="../../assets/images/cloudDesktop/remote.svg"
                alt=""
                width="20"
              ><el-tooltip
                t:if="rustDeskId"
                :content="rustDeskId"
              >
                <a style="margin: -4px 0 0 4px; text-decoration:none">CloudDesk</a>
              </el-tooltip>
            </button>
            <button
              v-else
              style="float:right"
              disabled="true"
            >
              <img
                src="../../assets/images/cloudDesktop/remote_disable.svg"
                alt=""
                width="20"
              >
              <span style="margin: -4px 0 0 4px; color: #ccc">CloudDesk</span>
            </button>
          </span>

          <button
            v-if="cookieDesk==='true' && isRunning"
            style="float:right"
          >
            <i
              class="el-icon-s-platform"
              style="color: rgb(18, 150, 219); font-size: 24px; margin: -2px 0 0 0"
            />
            <el-tooltip :content="t('pai.vmset.tips.vmRemoteLogin')">
              <a
                style="margin: -4px 0 0 4px; text-decoration:none"
                @click="onOpenCloudDesk"
              >Rdp</a>
            </el-tooltip>
          </button>
        </div>
      </div>
    </div>

    <el-dialog
      :title="t('pai.detail.vmset.tab.backUpManagement.backUpVm')"
      :visible.sync="backUpDialogVisible"
      width="530px"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="140px"
      >
        <el-form-item
          :label="t('pai.detail.vmset.tab.backUpManagement.name')"
          prop="name"
        >
          <el-input v-model="form.name" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="backUpDialogVisible = false">{{
          t('pai.detail.vmset.cancel')
        }}</el-button>
        <el-button
          type="primary"
          @click="onBackUp"
        >{{
          t('pai.detail.vmset.confirm')
        }}</el-button>
      </span>
    </el-dialog>
    <DeleteVmModal v-if="deleteVmModalVisible" />
    <CloneVmModal v-if="cloneVmModalVisible" />
  </div>
</template>

<style lang="scss" scoped>
.vmDetailPages {
  background-color: #fafafa;

  .top {
    margin: 10px;
    display: flex;

    ::v-deep .el-row {
      height: 30px;
      line-height: 30px;

      .el-col-6 {
        width: unset;
      }
    }

    ::v-deep .el-card__body {
      width: 100%;
    }
  }

  .tabs {
    margin: 12px 10px 0 10px;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    margin: 0 10px;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-radius: 5%;
    background-color: #fff;
  }

  ::v-deep .count-gauge {
    padding: 0 10px;
    margin: 0 5px;
  }

  .addDiskStyle {
    .el-input,
    .el-select,
    .el-input-number {
      width: 300px;
    }

    ::v-deep .el-descriptions-item__label {
      width: 100px;
    }
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    width: 99%;
  }

  ::v-deep
  .el-tabs--top.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:nth-last-child(2) {
    position: absolute;
    right: 100px;
  }

  ::v-deep
  .el-tabs--top.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:last-child {
    position: absolute;
    right: 0;
  }
}
</style>
