<script>
import { Card } from '@components/Card';
import { PAI_RESOURCES } from '../config/types';
import { createYaml } from '@/shell/utils/create-yaml';
import { clone } from '@shell/utils/object';
import { SCHEMA } from '@shell/config/types';

export default {
  name:       'RestoreDialog',
  components: { Card },

  props: {
    value: {
      type:     Object,
      required: true,
    },
  },
  async fetch() {
    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.RESTORE)) {
      this.restoreData = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.RESTORE });
    }
  },
  data() {
    const time = new Date().valueOf();
    const restoreYaml = {
      apiVersion: 'velero.io/v1',
      kind:       'Restore',
      metadata:   {
        annotations: { createby: '' }, // # 添加此annotation，当前操作用户的user name
        labels:      { displayName: '' },
        name:        '', // # 修改这个，可以自定义一个固定的格式,例如restore-ns-{ns name}-时间戳
        namespace:   'velero'
      },

      spec: {
        backupName:        '', // # 修改这个，对应backup名字
        labelSelector:     { matchLabels: { 'com.tdology.virt.vmsets': this.value.spec.labelSelector.matchLabels['com.tdology.virt.vmsets'] } },
        excludedResources:
            ['nodes', 'events', 'events.events.k8s.io', 'backups.velero.io', 'restores.velero.io', 'resticrepositories.velero.io', 'csinodes.storage.k8s.io', 'volumeattachments.storage.k8s.io', 'backuprepositories.velero.io'],
      }
    };
    const checkRestore = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.t('pai.detail.vmset.enterName')));
      } else if ((/[A-Z]/g).test(value)) {
        return callback(new Error(this.t('pai.detail.vmset.notUppercase')));
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(value)) {
        return callback(new Error(this.t('pai.detail.vmset.specialCharacters')));
      } else if (/[\u4E00-\u9FA5]/g.test(value)) {
        return callback(new Error(this.t('pai.detail.vmset.verifyChinese')));
      }
      this.restoreData.forEach((item) => {
        if (item.metadata.name === value) {
          return callback(new Error(this.t('pai.detail.vmset.duplicateNames')));
        }
      });
    };

    return {
      restoreYaml,
      restoreForm:       { name: `restore-ns-${ this.value.metadata.namespace }-${ time }` },
      restoreData:       [],
      currentBackupName: '',
      rules:             { name: [{ validator: checkRestore, trigger: 'blur' }] },
    };
  },
  computed: {
    restoreSchema() {
      const inStore = this.$store.getters['currentStore'](this.value);

      return this.$store.getters[`${ inStore }/schemaFor`](PAI_RESOURCES.RESTORE);
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async onRestore() {
      if (this.restoreForm.name === '') {
        this.$message.error(this.t('pai.detail.vmset.enterName'));

        return;
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

        return;
      } else if (/[\u4E00-\u9FA5]/g.test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.verifyChinese'));

        return;
      } else if ((/[A-Z]/g).test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.notUppercase'));

        return;
      }
      if (this.restoreData.length > 0) {
        for (const item in this.restoreData) {
          if (this.restoreData[item].metadata.name === this.restoreForm.name) {
            this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

            return;
          }
        }
      }

      const opt = {
        method:  'post',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        url:  `v1/${ PAI_RESOURCES.RESTORE }`,
      };
      const time = new Date().valueOf();

      this.restoreYaml.metadata.annotations.createby = this.backupBy;
      this.restoreYaml.spec.backupName = this.value.metadata.name;
      this.restoreYaml.metadata.name = `restore-ns-${ this.restoreForm.name }-${ time }`;
      this.restoreYaml.metadata.labels.displayName = this.restoreForm.name;
      try {
        opt.data = this.toRestoreYaml(this.restoreYaml);
        await this.restoreSchema.$ctx.dispatch('request', opt);
      } catch (e) {
        console.log(e);
      }
      this.$message({
        type:    'success',
        message: this.t('pai.detail.vmset.restoreSuccess')
      });
      this.close();
    },
    toRestoreYaml(value) {
      const inStore = this.$store.getters['currentStore'](value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const clonedResource = clone(value);

      return createYaml(schemas, PAI_RESOURCES.RESTORE, clonedResource);
    },
  },
};
</script>

<template>
  <Card
    class="prompt-rotate"
    :show-highlight-border="false"
  >
    <h4
      slot="title"
      class="text-default-text"
      v-html="t('pai.detail.vmset.tab.backUpManagement.restoreVm')"
    />
    <template #body>
      <el-form
        ref="form"
        :model="restoreForm"
        :rules="rules"
        label-width="140px"
      >
        <el-form-item
          :label="t('pai.detail.vmset.tab.backUpManagement.restoreName')"
        >
          <el-input v-model="restoreForm.name" />
        </el-form-item>
      </el-form>
    </template>
    <div
      slot="actions"
      class="buttons"
    >
      <button
        class="btn role-secondary mr-10"
        @click="close"
      >
        {{ t('generic.cancel') }}
      </button>

      <button
        class="btn role-primary"
        @click="onRestore"
      >
        {{ t('generic.confirm') }}
      </button>
    </div>
  </Card>
</template>
<style lang="scss" scoped>
.prompt-rotate {
  margin: 0;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.el-input {
  border: none;
  border: 2px solid #5084AA;
  border-radius: 5px;
  width: 400px;
  margin-top: 10px;
}
</style>
