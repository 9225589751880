<script>
import CreateEditView from '@/shell/mixins/create-edit-view';
import FormValidation from '@/shell/mixins/form-validation';
import { POD } from '@/shell/config/types';
import WorkLoadMixin from '../mixins/workload';
import Tabbed from '../../../components/Tabbed/index.vue';
import Tab from '../../../components/Tabbed/Tab.vue';
import { mapState } from 'vuex';
import { REG_URL } from '~/pkg/pai/config/settings';

export default {
  components: {
    Tabbed,
    Tab,
  },
  name:   'Workload',
  mixins: [CreateEditView, FormValidation, WorkLoadMixin], // The order here is important since WorkLoadMixin contains some FormValidation configuration
  props:  {
    value: {
      type:     Object,
      required: true,
    },

    mode: {
      type:    String,
      default: 'edit',
    },
  },
  data() {
    const image = this.value.spec.containers[0].image;

    return {
      activeContainerIndex: 0,
      networkData:          [],
      networks:             [],
      networkOptions:       [],
      allImages:            [],
      radio:                '1',
      loadData:             [],
      inputImage:           image,
      selectImage:          image,
    };
  },
  computed: {
    ...mapState(['isRancher']),
    namespace() {
      return this.value?.metadata?.namespace || '';
    }
  },
  watch: {
    namespace() {
      this.updateNetworkOptions();
    },
    activeContainerIndex(val) {
      const image = this.value.spec.containers[val].image;

      this.inputImage = image;
      this.selectImage = image;
    },
    inputImage(val) {
      this.$set(this.allContainers[this.activeContainerIndex], 'image', val);
    },
    selectImage(val) {
      this.$set(this.allContainers[this.activeContainerIndex], 'image', val);
    },
  },
  async created() {
    try {
      const schemas = this.$store.getters['cluster/schemaFor'](POD);
      const opt = {
        method:  'get',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        REG_URL,
      };
      const data = await schemas.$ctx.dispatch('request', { opt });

      const arr = [];

      for (const key in data) {
        for (const key1 in data[key]) {
          data[key][key1].forEach((item) => {
            arr.push(`${ key }/${ key1 }:${ item }`);
          });
        }
      }
      this.allImages = arr;
    } catch (e) {

    }
  },
  methods: {
    onActiveContainer(i) {
      this.activeContainerIndex = i;
    },
    onClose(tab) {
      this.removeContainer(tab);
      this.onActiveContainer(0);
    },
    updateNetworkOptions() {
      this.networkOptions = this.networks.filter((v) => v.metadata.namespace === this.value.metadata.namespace).map((v) => {
        return {
          label: v.alias,
          value: v,
        };
      });
    },
  },
};
</script>

<template>
  <form
    class="filled-height"
  >
    <div style="border: 1px solid #dcdee7;border-radius: 2px">
      <div class="containers">
        <el-tag
          v-for="(tab, i) in allContainers"
          :key="tab[idKey]"
          :class="{'active':i===activeContainerIndex }"
          @click="onActiveContainer(i)"
          @close="onClose(tab)"
        >
          <img
            v-if="!tab.image"
            src="../../../assets/images/common/warn.svg"
            style="width: 15px; height: 15px; position: relative; top: 4px;"
          >
          {{ tab.name }}
        </el-tag>
      </div>
      <div
        v-for="(tab, i) in allContainers"
        :key="tab[idKey]+i"
      >
        <Tabbed
          v-if="i===activeContainerIndex"
          :side-tabs="true"
          :weight="99"
        >
          <Tab
            :label="t('pai.vmset.info')"
            name="info"
            :weight="tabWeightMap['general']"
            :error="tabErrors.general"
          >
            <div>
              <div
                :style="{'align-items':'center'}"
                class="row mb-20"
              >
                <div class="col span-6">
                  <LabeledInput
                    v-model="allContainers[activeContainerIndex].name"
                    :mode="mode"
                    :label="t('workload.container.containerName')"
                    disabled
                  />
                </div>
                <div class="col span-6">
                  <RadioGroup
                    :mode="mode"
                    :value="allContainers[activeContainerIndex]._init"
                    name="initContainer"
                    :options="[true, false]"
                    :labels="[t('workload.container.init'), t('workload.container.standard')]"
                    disabled
                    @input="updateInitContainer($event, allContainers[activeContainerIndex])"
                  />
                </div>
              </div>
              <h3>{{ t('workload.container.titles.image') }}</h3>
              <el-radio-group
                v-model="radio"
              >
                <el-radio
                  v-model="radio"
                  label="1"
                >
                  {{ t('pai.apps.container.customInput') }}
                </el-radio>
                <el-radio
                  v-model="radio"
                  label="2"
                >
                  {{ t('pai.apps.container.selectMirror') }}
                </el-radio>
              </el-radio-group>
              <div class="row mb-20">
                <div class="col span-6">
                  <LabeledInput
                    v-if="radio==='1'"
                    v-model.trim="inputImage"
                    :mode="mode"
                    :label="t('workload.container.image')"
                    :placeholder="t('generic.placeholder', {text: 'nginx:latest'}, true)"
                    :rules="fvGetAndReportPathRules('image')"
                  />
                  <LabeledSelect
                    v-if="radio==='2'"
                    v-model.trim="selectImage"
                    :mode="mode"
                    :label="t('workload.container.image')"
                    :options="allImages"
                    :placeholder="t('generic.placeholder', {text: 'nginx:latest'}, true)"
                    :rules="fvGetAndReportPathRules('image')"
                  />
                </div>
                <div class="col span-6">
                  <LabeledSelect
                    v-model="allContainers[activeContainerIndex].imagePullPolicy"
                    :label="t('workload.container.imagePullPolicy')"
                    :options="pullPolicyOptions"
                    :mode="mode"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="col span-6">
                  <LabeledSelect
                    v-model="imagePullSecrets"
                    :label="t('workload.container.imagePullSecrets')"
                    :multiple="true"
                    :taggable="true"
                    :options="imagePullNamespacedSecrets"
                    :mode="mode"
                    option-label="metadata.name"
                    :reduce="service=>service.metadata.name"
                    :create-option="createOption"
                    disabled
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabbed>
      </div>
    </div>
  </form>
</template>

<style lang='scss'>
.containers{
  padding: 10px;
  width: 100%;

  .el-tag {
    margin: 5px;
    cursor: pointer;
    border-radius: 35px;
  }

  .active{
    border-color: var(--primary);
  }
}
::v-deep .tab-header[data-v-1d6db493]{
  display: none;
}
</style>
