<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import SortableTable from '@shell/components/SortableTable';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { PAI_POD } from '../config/types';

export default {
  components: { SortableTable },

  mixins: [CreateEditView],

  props: {
    mode: {
      default: 'create',
      type:    String,
    },
    value: {
      required: true,
      type:     Object,
    },
  },

  data() {
    const headers = [
      {
        name:  'name',
        label: this.t('tableHeaders.name'),
        value: 'name',
        sort:  'name:desc',
      },
      {
        name:  'namespace',
        label: this.t('tableHeaders.namespace'),
        value: 'namespace',
        sort:  'namespace',
      },
      {
        name:          'podname',
        label:         this.t('pai.overview.pod'),
        value:         'podname',
        sort:          'podname',
        formatter:     'Link',
        formatterOpts: { options: { internal: true }, urlKey: 'url' },
      },
      {
        name:  'CPU',
        label: 'CPU',
        value: 'cpu',
        sort:  'cpu',
      },
      {
        name:  'numa',
        label: 'NUMA',
        value: 'numa',
        sort:  'numa',
      },
    ];

    return { headers };
  },

  computed: {
    rows() {
      const rows = [];

      if (this.value?.status?.rtinfo?.rtcontainers) {
        const keys = Object.keys(this.value?.status?.rtinfo?.rtcontainers);

        if (keys.length) {
          keys.forEach((key) => {
            const arr = key.split('/');

            if (arr.length === 3 ) {
              const namespace = arr[0];
              const podname = arr[1];
              const name = arr[2];
              const value = this.value?.status?.rtinfo?.rtcontainers[key];
              const cpu = value.cpu;
              const numa = value.numa;

              rows.push({
                namespace,
                podname,
                name,
                cpu,
                numa,
                url: `/${ PAI }/c/${ this.$route.params.cluster }/${ PAI_POD }/${ namespace }/${ podname }`,
              });
            }
          });
        }
      }

      return rows;
    }
  },
};
</script>

<template>
  <SortableTable
    v-if="rows.length"
    :rows="rows"
    :headers="headers"
    :table-actions="false"
    :row-actions="false"
    :search="true"
  />
</template>

<style lang="scss" scoped>
</style>
