<script>
import { NETWORK_ATTACHMENT } from '@/shell/config/types';
import { mapGetters } from 'vuex';
import LiveDate from '@/shell/components/formatter/LiveDate';
import { PAI_RESOURCES } from '../../../config/types';
export default {
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
  },
  components: { LiveDate },
  data() {
    return {
      tableData:           [],
      networks:            [],
      dialogVisible:       false,
      networkOptions:      [],
      networkOptionsValue: '',
      filterValue:         '',
      nadList:             [],
    };
  },
  async fetch() {
    try {
      if (this.$store.getters['cluster/schemaFor'](NETWORK_ATTACHMENT)) {
        this.networks = await this.$store.dispatch('cluster/findAll', { type: NETWORK_ATTACHMENT });
      }
      if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.NAD)) {
        this.nadList = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.NAD });
      }
    } catch (e) {

    }
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    newTableData() {
      const info = this.value?.status?.instances;
      let defaultNetwork = { name: this.t('pai.vmset.defaultNetwork') };
      const list = [];

      for (const key in info) {
        list.push({ podName: key, ...info[key] });
      }

      list.filter((list) => list.podName === this.podName).map((net) => {
        net?.net?.forEach((n, i) => {
          if (n.interface === 'eth0') {
            defaultNetwork = {
              interface: n.interface,
              name:      n.name,
              ip:        n.ips
            };
          }
        });
      });
      const tableData = this.value?.spec?.nomanagenic ? [] : [defaultNetwork];
      this.nadList.forEach((item, index) => {
        const config = item.spec.config ? JSON.parse(item.spec.config) : '';

        this.value.spec?.nics?.forEach((n, i) => {
          if (item.metadata.name === n) {
            tableData.push({
              name:            item.metadata.name,
              alias:           item.alias,
              type:            config?.type,
              ip:              config?.ipam?.addresses? [config?.ipam?.addresses[0]?.address]: '',
              addressType:     config?.ipam?.type,
              hostNetworkName: config?.uplink,
              createTime:      item.metadata?.creationTimestamp
            });
          }
        });
      });

      // 创建/编辑时删除了管理网卡, 网络信息列表则不展示管理网卡信息
      let newTableData = [];

      if (this.value?.spec?.nomanagenic === true) {
        newTableData = tableData.filter((v) => v.interface !== 'eth0');
      } else {
        newTableData = tableData;
      }

      return newTableData;
    }
  },
  methods: {
    deleteNetwork(value) {
      const h = this.$createElement;
      // 根据删除的是否为管理网卡给出不同提示信息
      const confirmText = value?.name === this.t('pai.vmset.defaultNetwork') ? h('div', null, [
        h('span', { style: 'font-size:18px;position: relative;top: -9px;left: -40px;' }, this.t('pai.detail.vmset.tooltip')),
        h('p', { style: 'font-size:14px;' }, this.t('pai.vmset.network.removeDefaultTips')),
        h('p', { style: 'margin-top:10px;font-size:12px;color:#CECECE' }, this.t('pai.vmset.network.subtitleTips'))
      ]) : h('div', null, [
        h('span', { style: 'font-size:18px;position: relative;top: -9px;left: -40px;' }, this.t('pai.detail.vmset.tooltip')),
        h('p', { style: 'font-size:14px;' }, this.t('pai.detail.vmset.confirmDelete')),
      ]);

      this.$confirm(this.t('pai.detail.vmset.tooltip'), {
        message:           confirmText,
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(async() => {
        if(value?.name === this.t('pai.vmset.defaultNetwork')) {
          this.value.spec.nomanagenic = true;
        } else {
          const index = this.value.spec.nics.findIndex((i) => i === value);
          this.value.spec.nics.splice(index, 1);
        }
        await this.value.save();
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteWaitVm')
        });
      }).catch((e) => {
        console.log(e)
      });
    }
  }
};
</script>

<template>
  <el-card>
    <el-row>
      <el-col :span="24">
        <el-input
          v-model="filterValue"
          :placeholder="t('pai.detail.vmset.filter')"
          prefix-icon="el-icon-search"
          style="float: right"
          size="small"
        />
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? newTableData.filter(item => item?.name?.includes(filterValue) || item?.alias?.includes(filterValue)):newTableData"
          class="netWorkTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
            width="50"
          />
          <el-table-column
            prop="name"
            :label="t('pai.detail.vmset.tab.networkManagement.name')"
            width="160"
          />
          <el-table-column
            prop="alias"
            :label="t('tableHeaders.alias')"
            width="110"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.networkManagement.type')"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.interface === 'eth0' ? '管理网卡' : scope.row.type }}
            </template>
          </el-table-column>
          <el-table-column
            prop="addressType"
            :label="t('pai.detail.vmset.tab.networkManagement.addressType')"
          />
          <el-table-column
            prop="ipAddress"
            :label="t('pai.detail.vmset.tab.overview.ipAddress')"
            width="150"
          >
            <template slot-scope="scope">
              <el-tooltip
                effect="light"
              >
                <div slot="content">
                  <div
                    v-for="(item, index) in scope.row.ip"
                    :key="index"
                    class="content"
                  >
                    <p style="font-size: 14px">
                      {{ item }}
                    </p>
                  </div>
                </div>
                <template>
                  <div
                    v-if="scope.row.ip && scope.row.ip.length"
                    class="content"
                  >
                    {{ scope.row.ip[0] }}
                    <i class="icon el-icon-s-unfold" />
                  </div>
                </template>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="hostNetworkName"
            :label="t('pai.detail.vmset.tab.networkManagement.hostNetworkName')"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.networkManagement.survivalTime')"
          >
            <template slot-scope="scope">
              <div>
                <LiveDate :value="scope.row.createTime" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                :disabled="newTableData.length <= 1"
                @click="deleteNetwork(scope.row)"
              >
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.netWorkTable{
  padding: 0 20px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
.el-input, .el-select, .el-input-number{
  width: 300px;
}
::v-deep .el-message-box__status{
  position: absolute;
  top: 70%;
}
</style>
