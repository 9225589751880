<script>
import AliasNsDescription from '../components/form/AliasNsDescription.vue';
import CruResource from '@shell/components/CruResource';
import CreateEditView from '@shell/mixins/create-edit-view';
import FormValidation from '@shell/mixins/form-validation';
import Loading from '@shell/components/Loading.vue';
import { NAMESPACE, PVC, SECRET, STORAGE_CLASS } from '@shell/config/types';
import { PAI_RESOURCES } from '~/pkg/pai/config/types';
import { ALIAS, IMAGE as IMAGES, PVC_LABELS, PVC_ANNOTATIONS } from '~/pkg/pai/config/labels-annotations';
import { get, set } from '@shell/utils/object';
import { COMMAND_HASH_MPA, IMAGE } from '../config/settings';
import { convertUnitToG } from '~/pkg/pai/utils/units';
import SectionTitle from '../components/form/SectionTitle.vue';
import { _CREATE, _EDIT } from '@shell/config/query-params';
import { mapGetters } from 'vuex';
import { PRODUCT_NAME } from '../config/pai';
import { VM_POWER_STATES_ENUM } from '@/pkg/pai/plugins/pai-resource-class';
import { delay } from '../utils';

export default {
  props: {
    value: {
      type:     Object,
      required: true,
    },
    mode: {
      type:     String,
      required: true,
    },
  },
  async fetch() {
    const vms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });

    this.pvcs = await this.$store.dispatch('cluster/findAll', { type: PVC });
    this.scs = await this.$store.dispatch('cluster/findAll', { type: STORAGE_CLASS });
    // 检查是否安装driver
    const scs = this.scs.map((v) => v.name);
    const drivers = ['longhorn', 'local-path'];

    drivers.forEach((v) => {
      if (scs.includes(v)) {
        const index = scs.findIndex((name) => name === v);

        this.drivers.push(this.scs[index].provisioner);
      }
    });
    if (this.drivers.length === 1) {
      set(this.value, 'spec.driver', this.drivers[0]);
    } else if (!this.drivers.length) {
      set(this.value, 'spec.driver', '');
    }
    // 根据云主机的状态设置options
    const vmsOptions = [];

    for await (const item of vms) {
      const ShutdownPods = item.instances.filter((v) => [VM_POWER_STATES_ENUM.Succeeded, VM_POWER_STATES_ENUM.Pending, VM_POWER_STATES_ENUM.Error].includes(v.status));
      const vmPods = item.instances.map((v) => {
        // 选择云主机组时级联第三级数据
        const selectCurrentPvc = this.pvcs?.filter((p) => p.labels && p.labels[PVC_LABELS.MOUNT_POD] === v.name && p.labels[PVC_LABELS.MOUNT_VM] === item.name && (p?.annotations[PVC_ANNOTATIONS.TYPE] === 'vmosdisk' || p?.annotations[PVC_ANNOTATIONS.TYPE] === 'datadisk'));
        const threeChildren = [];

        selectCurrentPvc.forEach((it) => {
          threeChildren.push({
            value:   `${ this.getPvcType(it.metadata.annotations[PVC_ANNOTATIONS.TYPE]) }-${ it.spec.storageClassName }-${ convertUnitToG(it.spec.resources.requests.storage) }Gi`,
            label:   `${ this.getPvcType(it.metadata.annotations[PVC_ANNOTATIONS.TYPE]) }-${ it.spec.storageClassName }-${ convertUnitToG(it.spec.resources.requests.storage) }Gi`,
            pvcName: it.metadata.name
          });
        });

        return {
          value: v.name, label: v.name, disabled: ShutdownPods.length === 0, children: threeChildren
        };
      });
      const name = (item.metadata && item.metadata.annotations && item.metadata.annotations[ALIAS]) ? `${ item.metadata.annotations[ALIAS] }(${ item.metadata.name })` : item.metadata.name;
      const disabled = item.powerState !== VM_POWER_STATES_ENUM.Off || vmPods.length === 0;

      vmsOptions.push({
        label: name, value: item.metadata.name, resource: item, disabled, children: vmPods,
      });
    }
    this.vmsOptions = vmsOptions.sort((a, b) => (`${ a.disabled }`).localeCompare(`${ b.disabled }`));
    let vm = '';

    if (this.$route.query.mode === 'makeImage' && this.$route.query.vm) {
      const vmId = this.$route.query.vm;

      vm = vmsOptions.find((v) => v.resource.id === vmId);
      if (vm) {
        this.$nextTick(() => {
          this.$set(this.form, 'vm', [vm.value, vm.children[0].value, vm.children[0]?.children[0]?.value]);
        });
        await this.onChangeVm([vm.value, vm.children[0].value, vm.children[0]?.children[0]?.value]);
      }
    } else if (this.$route.query.mode === _EDIT && this.value.labels[IMAGES.DEPEND_VM] && this.value.labels[IMAGES.DEPEND_POD]) {
      this.$nextTick(() => {
        this.$set(this.form, 'vm', [this.value.labels[IMAGES.DEPEND_VM], this.value.labels[IMAGES.DEPEND_POD], this.value.annotations[IMAGES.DEPEND_PVC]]);
      });
    }
    this.namespaces = await this.$store.dispatch('cluster/findAll', { type: NAMESPACE });
    const secrets = await this.$store.dispatch('cluster/findAll', { type: SECRET });

    this.secrets = secrets.filter((v) => v.isRegistry);
    if (this.value.spec?.share_to) {
      const namespaces = this.value.spec.share_to;

      if (Array.isArray(namespaces) && namespaces.length) {
        this.onNamespacesChange(namespaces);
      }
    }
    this.images = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_IMAGE });
  },
  components: {
    SectionTitle,
    Loading,
    CruResource,
    AliasNsDescription,
  },
  mixins: [CreateEditView, FormValidation],
  data() {
    if (!this.value.metadata.name) {
      this.value.metadata.name = `image${ parseInt(new Date().getTime() / 1000) }`;
    }
    if (!get(this.value, 'spec.source_type')) {
      set(this.value, 'spec.source_type', 'export-from-volume');
    }
    if (!get(this.value, 'spec.os')) {
      if (this.mode === _EDIT) {
        set(this.value, 'spec.os', 'tool');
      } else {
        set(this.value, 'spec.os', '');
      }
    }
    if (!get(this.value, 'spec.arch')) {
      set(this.value, 'spec.arch', '');
    }
    if (!get(this.value, 'spec.agent')) {
      set(this.value, 'spec.agent', '');
    }
    if (!get(this.value, 'spec.driver')) {
      set(this.value, 'spec.driver', '');
    }
    if (!get(this.value, 'spec.source_parameters.exportType')) {
      set(this.value, 'spec.source_parameters.exportType', '');
    }
    if (!get(this.value, 'spec.size')) {
      set(this.value, 'spec.size', '');
    }
    if (!this.value.metadata.labels[IMAGES.ISO]) {
      set(this.value, 'metadata.labels', { [IMAGES.ISO]: 'false' });
    }
    const defaultNamespaces = 'allNamespaces';
    const tempSelection = '';

    return {
      tempSelection,
      brandOptions: [],
      name:         this.value.metadata.name,
      IMAGE,
      IMAGES,
      namespaces:   [],
      pvcs:         [],
      images:       [],
      scs:          [],
      secrets:      [],
      vmsOptions:   [],
      form:         {
        authNamespaces: [defaultNamespaces],
        vm:             '',
        size:           convertUnitToG(this.value.spec.size),
      },
      typeOptions: [
        { label: this.t('pai.vmset.image.exists'), value: 'export-from-volume' },
        { label: 'URL', value: 'download' },
        { label: this.t('pai.vmset.image.upload'), value: 'upload' }
      ],
      defaultNamespaces,
      fileList:    [],
      minFileSize: 1,
      drivers:     [],
      osTypes:     IMAGE.OS.map((v) => {
        return { label: v, value: v };
      }),
      oldSize:        null,
      pvc:            '',
      driverDisabled: false,
    };
  },
  mounted() {
    const brandOptions = [];
    const files = require.context('@pkg/pai/assets/images/imageLogo', true).keys();

    files.forEach((e) => {
      brandOptions.push(require(`@pkg/pai/assets/images/imageLogo${ e.slice(1) }`));
    });
    this.brandOptions = brandOptions;
    if (this.value?.spec?.logo) {
      this.$nextTick(() => {
        setTimeout(() => {
          const logoName = this.value.spec.logo;

          if (logoName?.includes('.')) {
            const url = this.brandOptions.find((v) => v.includes(logoName.split('.')[0]));

            if (url) {
              this.changeSelection(url);
            }
          }
        }, 1000);
      });
    }
  },
  computed: {
    ...mapGetters(['currentCluster']),
    isEdit() {
      return this.mode === _EDIT;
    },
    isCreate() {
      return this.mode === _CREATE;
    },
    isMakeImage() {
      return this.mode === 'makeImage';
    },
    isLocalPath() {
      return this.value.spec.driver === IMAGE.DRIVER[1];
    },
    isDefaultImageUrl() {
      return this.value.spec.source_parameters?.imageurl?.includes('iboot:5000/vmimage/');
    },
    doneRoute() {
      return {
        name:   'pai-c-cluster-apps-charts',
        params: { product: PRODUCT_NAME, cluster: this.currentCluster.id },
        hash:   COMMAND_HASH_MPA.vm
      };
    },
    isIso() {
      return this.value.labels[IMAGES.ISO] === 'true';
    },
    isToolKit() {
      return this.value.labels[IMAGES.TOOL] === 'true';
    },
  },
  methods: {
    async saveOverride(btnCb) {
      // 保存
      if (!this.value.spec.alias) {
        this.$message({
          type:    'warning',
          message: `${ this.t('validation.required', { key: this.t('generic.name') }, true) }`,
        });
        btnCb(false);

        return;
      }
      const newSize = this.value.spec.size.replace('Gi', '');

      if (this.value.spec.source_type === 'export-from-volume' && newSize < this.oldSize) {
        this.$message({
          type:    'warning',
          message: this.t('pai.vmset.tips.image.fileSizeTips'),
        });
        btnCb(false);

        return;
      }
      if (this.value.spec.source_type === 'download' && !this.value.spec.source_parameters.url) {
        this.$message({
          type:    'warning',
          message: `${ this.t('validation.required', { key: this.t('pai.vmset.image.url') }, true) }`,
        });
        btnCb(false);

        return;
      }
      if (this.value.spec.source_type === 'export-from-volume' && !this.form.vm) {
        this.$message({
          type:    'warning',
          message: `${ this.t('validation.required', { key: this.t('pai.vmset.image.vm') }, true) }`,
        });
        btnCb(false);

        return;
      }
      if (this.isCreate && this.value.spec.source_type === 'upload' && !this.fileList.length) {
        this.$message({
          type:    'warning',
          message: this.t('pai.vmset.tips.image.nofile'),
        });
        btnCb(false);

        return;
      }
      if (!this.value.spec.driver) {
        this.$message({
          type:    'warning',
          message: this.t('pai.vmset.tips.image.noDriver'),
        });
        btnCb(false);

        return;
      }
      if (this.value.spec?.source_type === 'export-from-volume' && this.value.spec.driver === IMAGE.DRIVER[0] && !this.value.spec?.source_parameters?.exportType) {
        this.$message({
          type:    'warning',
          message: `${ this.t('validation.required', { key: this.t('pai.vmset.image.exportType') }, true) }`,
        });
        btnCb(false);

        return;
      }
      if (!this.value.spec.os) {
        this.$message({
          type:    'warning',
          message: `${ this.t('validation.required', { key: this.t('pai.vmset.image.os') }, true) }`,
        });
        btnCb(false);

        return;
      }
      for (const item in this.images) {
        if (this.isCreate && this.images[item].namespace === this.value.metadata.namespace && this.images[item].spec.alias === this.value.spec.alias) {
          this.$message({
            type:    'warning',
            message: `${ this.t('validation.duplicatedImageName', true) }`,
          });
          btnCb(false);

          return;
        }
      }
      if (!this.isToolKit) {
        if (!this.value.spec.agent) {
          this.$message({
            type:    'warning',
            message: `${ this.t('validation.required', { key: 'πKite' }, true) }`,
          });
          btnCb(false);

          return;
        }
        if (!this.value.spec.arch) {
          this.$message({
            type:    'warning',
            message: `${ this.t('validation.required', { key: this.t('pai.vmset.image.framework') }, true) }`,
          });
          btnCb(false);

          return;
        }
        if (this.isLocalPath && !this.value.spec.source_parameters.parent) {
          this.$message({
            type:    'warning',
            message: `${ this.t('validation.required', { key: this.t('pai.vmset.image.parent') }, true) }`,
          });
          btnCb(false);

          return;
        }
      }
      // 安装工具包无需配置镜像参数
      if (this.isToolKit) {
        this.$delete(this.value.spec, 'arch');
        this.$delete(this.value.spec.source_parameters, 'exportType');
      }

      const name = `${ this.value.metadata.namespace }-${ this.value.metadata.name }`;
      const schema = await this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VM_IMAGE);

      try {
        if (this.value.spec.source_type === 'upload' && this.mode === _CREATE) {
          const fileInfoOpt = {
            url:    `/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages`,
            method: 'post',
            data:   {
              expectedChecksum: '',
              name,
              parameters:       {},
              sourceType:       'upload',
            },
            headers: {
              'content-type': 'application/json',
              accept:         'application/json',
            },
          };
          const fileFormData = new FormData();
          const file = this.fileList[0].raw;

          fileFormData.append('chunk', file);
          const fileOpt = {
            url:     `/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages/${ name }?action=upload&size=${ this.fileList[0].size }`,
            method:  'post',
            data:    fileFormData,
            headers: { accept: '*/*' },
          };
          const fileInfoRes = await schema.$ctx.dispatch('request', { opt: fileInfoOpt });

          if (fileInfoRes && fileInfoRes._status === 200) {
            let canUpload = false;

            // The reason for the delay is that the back-end upload service cannot be started immediately.
            // The interface opened by the service has not been checked for the time being.
            // So it needs to delay 60s temporarily
            for (let i = 0; i < 30; i++) {
              await delay(2000);
              const res = await schema.$ctx.dispatch('request', {
                opt: {
                  url:    `/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages`,
                  method: 'get',
                }
              });

              if (res && res.data && res.data.length) {
                const currentBackingImage = res.data.find((item) => {
                  return item.name === name;
                });

                if (currentBackingImage && currentBackingImage.diskFileStatusMap) {
                  const diskMap = currentBackingImage.diskFileStatusMap;

                  canUpload = Object.keys(diskMap).some((key) => {
                    return diskMap[key].state === 'pending' || diskMap[key].state === 'ready';
                  });
                }
              }
              if (canUpload) {
                break;
              }
            }
            if (canUpload) {
              this.errors.push(this.t('pai.vmset.tips.image.uploading'));
              await schema.$ctx.dispatch('request', { opt: fileOpt });
            } else {
              await this.$message({
                type:    'success',
                message: 'Timeout waiting for the upload service initialization, please delete then recreate Backing Image.',
              });
            }
          }
          if (this.isLocalPath) {
            this.$set(this.value.spec.source_parameters, 'url', `http://longhorn-frontend.longhorn-system.svc/v1/backingimages/${ name }/download`);
          }
        }
        await this.value.save();
        if (this.$route.params.resource === PAI_RESOURCES.VM_IMAGE) {
          await btnCb(true);
          await this.$message({
            type:    'success',
            message: this.t('pai.labels.success')
          });
          await this.$router.push(this.doneRoute);
        } else {
          await this.$message({
            type:    'success',
            message: this.t('pai.vmset.tips.image.uploaded')
          });
        }
      } catch (e) {
        btnCb(false);
        let errMessage = '';

        if (e.code === 'AlreadyExists') {
          errMessage = `${ this.value.metadata.name } ${ this.t('pai.vmset.tips.alreadyExists') }!`;
        } else if (e.code === 'Conflict' && e.message.includes('please apply your changes to the latest version and try again')) {
          errMessage = this.t('pai.vmset.tips.conflictTips');
        } else if (e.data && e.data.includes('should be a multiple of 512 bytes')) {
          // 如果文件上传失败，则删除刚刚创建的backingImage
          try {
            await schema.$ctx.dispatch('request', {
              opt: {
                url:    `/k8s/clusters/local/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages/${ name }?action=backingImageCleanup`,
                method: 'delete',
              }
            });
            errMessage = e.data;
          } catch (e) {}
        } else {
          errMessage = e.message ? e.message : e.data;
        }
        await this.$message({
          type:    'warning',
          message: errMessage
        });
      }
    },
    done() {
      this.$router.go(-1);
    },
    getPvcType(status) {
      let type = '';

      switch (status) {
      case 'vmosdisk':
        type = this.t('pai.vmset.storage.os');
        break;
      case 'datadisk':
        type = this.t('pai.vmset.storage.data');
        break;
      default:
        type = '';
      }

      return type;
    },
    onNamespacesChange(e) {
      if (e.includes(this.defaultNamespaces) && !this.form.authNamespaces.includes(this.defaultNamespaces)) {
        this.form.authNamespaces = [this.defaultNamespaces];
      } else if (e.includes(this.defaultNamespaces) && this.form.authNamespaces.includes(this.defaultNamespaces)) {
        this.form.authNamespaces = typeof e === 'string' ? [e] : e;
        this.form?.authNamespaces?.splice(this.form.authNamespaces.indexOf(this.defaultNamespaces), 1);
      } else {
        this.form.authNamespaces = typeof e === 'string' ? [e] : e;
      }

      if (this.form.authNamespaces.includes(this.defaultNamespaces) || this.form.authNamespaces.length === 0) {
        this.value.spec.source_parameters.global = 'true';
        this.$delete(this.value.spec, 'share_to');
      } else {
        this.$set(this.value.spec, 'share_to', this.form.authNamespaces);
        this.value.spec.source_parameters.global = 'false';
      }
    },

    onDriverChange(e) {
      if (e === IMAGE.DRIVER[1] && !this.value.spec.source_parameters.imageurl) {
        this.setDefaultImageUrl();
      }
      if (e === IMAGE.DRIVER[0] && this.value.spec.source_type === 'export-from-volume') {
        this.value.spec.source_parameters.exportType = IMAGE.EXPORT_TYPE[1];
      }
      if (this.pvc && this.value.spec.source_type === 'export-from-volume') {
        if (e === IMAGE.DRIVER[0]) {
          this.value.spec.source_parameters.volumeName = this.pvc?.spec?.volumeName;
        } else {
          this.value.spec.source_parameters.volumeName = this.pvc?.name;
        }
      }
      if (e === IMAGE.DRIVER[1]) {
        set(this.value, 'spec.source_parameters.engine', 'zfs');
        set(this.value, 'spec.source_parameters.parent', '');
        set(this.value, 'spec.source_parameters.secret', '');
        set(this.value, 'spec.source_parameters.insecure', 'http');
      } else {
        this.$delete(this.value.spec.source_parameters, 'imageurl');
        this.$delete(this.value.spec.source_parameters, 'engine');
        this.$delete(this.value.spec.source_parameters, 'parent');
        this.$delete(this.value.spec.source_parameters, 'secret');
        this.$delete(this.value.spec.source_parameters, 'insecure');
      }
    },

    setDefaultImageUrl() {
      if (this.value.spec.source_parameters.imageurl?.includes(':')) {
        const tags = this.value.spec.source_parameters.imageurl.split(':');
        const tag = tags[tags.length - 1];

        this.$set(this.value.spec.source_parameters, 'imageurl', `iboot:5000/vmimage/${ this.value.metadata.name }:${ tag }`);
      } else {
      } this.$set(this.value.spec.source_parameters, 'imageurl', `iboot:5000/vmimage/${ this.value.metadata.name }:latest`);
    },
    onChangeVm(e) {
      this.driverDisabled = false;
      this.$set(this.value.metadata.labels, IMAGES.DEPEND_VM, e[0]);
      this.$set(this.value.metadata.labels, IMAGES.DEPEND_POD, e[1]);
      this.$set(this.value.metadata.annotations, IMAGES.DEPEND_PVC, e[2]);
      const pvcSizeIndex = e[2].lastIndexOf('-');

      this.onChangeSize(convertUnitToG(e[2].substring(pvcSizeIndex + 1, e[2].length)));
      // 查找对应的pv
      const pvc = this.pvcs?.find((v) => v.labels && v.labels[PVC_LABELS.MOUNT_POD] === e[1] && v.labels[PVC_LABELS.MOUNT_VM] === e[0] && (v?.annotations[PVC_ANNOTATIONS.TYPE] === 'vmosdisk' || v?.annotations[PVC_ANNOTATIONS.TYPE] === 'datadisk'));
      let pvName = '';
      let sc = '';

      if (pvc) {
        this.pvc = pvc;
        pvName = pvc.spec?.volumeName;
        sc = pvc.spec?.storageClassName;
      }
      if (pvName) {
        // 设置磁盘对应的sc信息
        if (sc) {
          const image = this.scs.find((v) => v.metadata.name === sc);

          if (image) {
            this.$set(this.value.spec, 'driver', image.provisioner);
            if (image.provisioner === IMAGE.DRIVER[0]) {
              this.value.spec.source_parameters.volumeName = pvName;
            } else {
              this.driverDisabled = true;
              this.value.spec.source_parameters.volumeName = pvc.name;
            }
            const annotations = get(image, 'metadata.annotations');

            this.$set(this.value.spec, 'os', annotations[IMAGES.OS]);
            this.$set(this.value.spec, 'arch', annotations[IMAGES.ARCH]);
            this.$set(this.value.spec, 'agent', annotations[IMAGES.AGENT]);
            this.$set(this.value.spec, 'size', `${ convertUnitToG(annotations[IMAGES.SIZE]) }Gi`);
            this.oldSize = convertUnitToG(annotations[IMAGES.SIZE]);
          }
        }
      } else {
        this.$message({
          type:    'warning',
          message: this.t('pai.vmset.tips.image.miss')
        });
      }
    },
    onChangeSize(e) {
      if (!e) {
        this.$nextTick(() => {
          this.form.size = 1;
        });
      } else {
        this.form.size = e;
      }
      this.$set(this.value.spec, 'size', `${ e || 1 }Gi`);
    },
    changeSelection(url) {
      if (this.$refs.select && this.$refs.select.$el) {
        const area = this.$refs.select.$el.children[0].children[0];

        this.tempSelection = ' ';
        const logoName = url.split('/')[url.split('/').length - 1];

        this.$set(this.value.spec, 'logo', `${ logoName.split('.')[0] }.svg`);
        area.setAttribute('style', `background:url(${ url }) no-repeat 10px;background-size: 30px 30px;color:#333;padding-left: 50px;`);
      }
    },
    onSecretChange(e) {
      if (e === 'new') {
        this.$router.push('/pai/c/local/pai.secret/create');
      }
    },
    onFileChange(file, fileList) {
      if (fileList.length) {
        const size = convertUnitToG(`${ file.size }b`);

        if (size > 10) {
          this.$message({
            type:    'warning',
            message: this.t('pai.vmset.tips.image.oversize')
          });
        }
        this.fileList = [file];

        this.minFileSize = Math.ceil(size || 1);
        this.form.size = this.minFileSize;
      }
    },
    onFileRemove() {
      this.fileList = [];
    },
    onSourceTypeChange(e) {
      this.driverDisabled = false;
      this.$refs.source_type.blur();
      this.form.size = 1;
      if (e === 'export-from-volume') {
        this.$set(this.value.metadata.labels, IMAGES.ISO, 'false');
        this.onFormatChange('false');
      }
    },
    onOsChange(e) {
      if (e === 'tool') {
        this.$set(this.value.metadata.labels, IMAGES.TOOL, 'true');
      } else {
        this.$delete(this.value.metadata.labels, IMAGES.TOOL);
      }
    },
    onFormatChange(e) {
      if (e === 'false') {
        // 选择镜像格式为系统镜像后,若已选择镜像类型为工具包则清空
        this.$nextTick(() => {
          if (this.isToolKit) {
            this.$set(this.value.spec, 'os', '');
            this.onOsChange('');
          }
        });
      } else {
        // 选择镜像格式为iso镜像后驱动方式driver固定为分布式存储
        this.$nextTick(() => {
          this.$set(this.value.spec, 'driver', IMAGE.DRIVER[0]);
        });
      }
    },
  },
  watch: {
    name() {
      if (this.isDefaultImageUrl) {
        this.setDefaultImageUrl();
      }
    },
    'value.spec.source_type'(nue) {
      if (nue === 'download') {
        // url的方式上传镜像
        this.$delete(this.value.spec.source_parameters, 'exportType');
      }
    },
    '$route.query'(nue) {
      // 返回表单编辑时回显logo
      if (!nue.as && this.value?.spec?.logo) {
        this.$nextTick(() => {
          const logoName = this.value.spec.logo;

          if (logoName?.includes('.')) {
            const url = this.brandOptions.find((v) => v.includes(logoName.split('.')[0]));

            if (url) {
              this.changeSelection(url);
            }
          }
        });
      }
    },
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <CruResource
    v-else
    :resource="value"
    :mode="mode"
    :apply-hooks="applyHooks"
    :errors="errors"
    @finish="saveOverride"
    @cancel="done"
  >
    <AliasNsDescription
      v-model="value"
      :mode="mode"
      alias-key="spec.alias"
      description-key="spec.desc"
    />
    <SectionTitle
      :value="t('cluster.tabs.basic')"
      style="margin-bottom: 20px"
    />
    <el-descriptions
      title=""
      direction="vertical"
      :colon="false"
      :column="2"
    >
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.type') }}
        </template>
        <el-select
          ref="source_type"
          v-model="value.spec.source_type"
          :disabled="isMakeImage || isEdit"
          @change="onSourceTypeChange"
        >
          <el-option
            v-for="(item) in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template v-if="value.spec.source_type==='download'">
          <template slot="label">
            {{ t('pai.vmset.image.url') }}
            <span style="color: red">*</span>
          </template>
          <el-input
            v-model="value.spec.source_parameters.url"
            :disabled="isEdit"
          >
            <el-tooltip
              slot="suffix"
              effect="light"
            >
              <div
                slot="content"
                v-html="t('pai.vmset.tips.image.url')"
              />
              <i
                style="color: red;font-size: 18px;line-height: 35px;"
                class="icon icon-warning"
              />
            </el-tooltip>
          </el-input>
        </template>
        <template v-if="value.spec.source_type==='export-from-volume'">
          <template slot="label">
            {{ t('pai.vmset.image.vm') }}
            <span style="color: red">*</span>
          </template>
          <el-cascader
            v-model="form.vm"
            :options="vmsOptions"
            :disabled="isMakeImage || isEdit"
            filterable
            @change="onChangeVm"
          >
            <template slot-scope="{ node, data }">
              <el-tooltip
                v-if="node.isLeaf===true"
                effect="light"
                :content="data.pvcName"
              >
                <span>{{ data.label }}</span>
              </el-tooltip>
              <span v-else>{{ data.label }}</span>
              <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
            </template>
          </el-cascader>
        </template>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="isCreate && value.spec.source_type==='upload'"
        :span="2"
      >
        <el-upload
          drag
          :auto-upload="false"
          action="#"
          :on-change="onFileChange"
          :file-list="fileList"
          :on-remove="onFileRemove"
          :disabled="isMakeImage || isEdit"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">
            {{ t('pai.vmset.tips.image.upload') }}
          </div>
          <div class="el-upload__tip">
            {{ t('pai.vmset.tips.image.extension') }}
          </div>
        </el-upload>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.size')+'(G)' }}
        </template>
        <el-input-number
          v-model="form.size"
          controls-position="right"
          :min="minFileSize"
          :max="10000"
          :disabled="isMakeImage || isEdit"
          :step="1"
          @input="onChangeSize"
        />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.auth') }}
        </template>
        <el-select
          :value="form.authNamespaces"
          filterable
          multiple
          collapse-tags
          @change="onNamespacesChange"
        >
          <el-option
            :value="defaultNamespaces"
            :label="t('nav.ns.all')"
          />
          <el-option
            v-for="(item,i) in namespaces.map(v=>v.metadata.name)"
            :key="item+i"
            :label="item"
            :value="item+''"
          />
        </el-select>
      </el-descriptions-item>
    </el-descriptions>
    <SectionTitle
      :value="t('pai.vmset.image.setting')"
      style="margin-bottom: 20px"
    />
    <el-descriptions
      title=""
      direction="vertical"
      :colon="false"
      :column="2"
    >
      <el-descriptions-item>
        <template slot="label">
          {{ t('storageClass.rbd.imageFormat.label') }}
        </template>
        <el-select
          v-model="value.metadata.labels[IMAGES.ISO]"
          :disabled="isMakeImage || isEdit || value.spec.source_type==='export-from-volume'"
          @change="onFormatChange"
        >
          <el-option
            v-for="item of [{label:t('pai.vmset.system.image'),value:'false'},{label:'ISO'+t('pai.detail.vmset.operationSystemImage'),value:'true'}]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.os') }}
          <span style="color:red">*</span>
        </template>
        <el-select
          v-model="value.spec.os"
          :disabled="isMakeImage || isEdit"
          @change="onOsChange"
        >
          <el-option
            v-for="item of isIso ? [...osTypes,{value:'tool',label:t('pai.vmset.image.toolkit')}] : osTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.driver') }}
          <span style="color:red">*</span>
        </template>
        <el-select
          v-model="value.spec.driver"
          :disabled="isEdit || isIso || !!(value.spec.source_type==='export-from-volume' && form.vm && driverDisabled)"
          @change="onDriverChange"
        >
          <el-option
            v-for="item of IMAGE.DRIVER"
            :key="item"
            :label="item.includes('longhorn') ? t('pai.detail.vmset.distStorage') : t('pai.detail.vmset.localStorage')"
            :disabled="!drivers.includes(item)"
            :value="item"
          />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.detail.vmset.operationSystemImage')+'logo' }}
        </template>
        <el-select
          ref="select"
          v-model="tempSelection"
          @change="changeSelection"
        >
          <el-option
            v-for="(item,i) in isToolKit ? brandOptions.filter(v=>v.includes('tool')) : brandOptions.filter(v=>!v.includes('tool'))"
            :key="item+i"
            :label="item"
            :value="item"
          >
            <img
              :src="item"
              style="height: 30px;margin-right: 10px;"
            >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item v-if="!isToolKit">
        <template slot="label">
          {{ t('pai.vmset.agent.label') }}
          <el-tooltip effect="light">
            <div
              slot="content"
              v-html="t('pai.vmset.agent.tip', {}, true)"
            />
            <i class="icon icon-info" />
          </el-tooltip>
          <span style="color:red">*</span>
        </template>
        <el-select
          v-model="value.spec.agent"
          :disabled="isMakeImage || isEdit"
        >
          <el-option
            v-for="item of IMAGE.AGENT.map(v=>{return{value:v,label:v==='guest' ? t('generic.yes') : t('generic.no')}})"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item v-if="!isToolKit">
        <template slot="label">
          {{ t('pai.vmset.image.framework') }}
          <span style="color:red">*</span>
        </template>
        <el-select
          v-model="value.spec.arch"
          :disabled="isMakeImage || isEdit"
        >
          <el-option
            v-for="item of IMAGE.FRAMEWORK"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-descriptions-item>
      <template v-if="value.spec.source_type==='export-from-volume' && value.spec.driver === IMAGE.DRIVER[0]">
        <el-descriptions-item>
          <template slot="label">
            {{ t('pai.vmset.image.exportType') }} <span style="color:red">*</span>
          </template>
          <el-select
            v-model="value.spec.source_parameters.exportType"
            :disabled="isEdit"
          >
            <el-option
              v-for="item of IMAGE.EXPORT_TYPE"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item />
      </template>
    </el-descriptions>
    <el-descriptions
      v-if="isLocalPath"
      title=""
      direction="vertical"
      :colon="false"
      :column="2"
    >
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.imageurl') }}
          <el-tooltip effect="light">
            <div
              slot="content"
              v-html="t('pai.vmset.tips.image.imageurl')"
            />
            <i class="icon icon-info" />
          </el-tooltip>
        </template>
        <el-input
          v-model="value.spec.source_parameters.imageurl"
          :disabled="isEdit"
        >
          <el-tooltip
            v-if="isDefaultImageUrl"
            slot="suffix"
            effect="light"
          >
            <div
              slot="content"
              v-html="t('pai.vmset.tips.image.iboot')"
            />
            <i
              style="color: red;font-size: 18px;line-height: 35px;"
              class="icon icon-warning"
            />
          </el-tooltip>
        </el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.parent') }}
          <span style="color:red">*</span>
        </template>
        <el-input
          v-model="value.spec.source_parameters.parent"
          :disabled="isEdit"
          :placeholder="t('pai.vmset.tips.zpool')"
        />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.insecure') }}
        </template>
        <el-select
          v-model="value.spec.source_parameters.insecure"
          :disabled="isEdit"
        >
          <el-option
            v-for="item of ['http','https']"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          {{ t('pai.vmset.image.secret') }}
          <el-tooltip effect="light">
            <div
              slot="content"
              v-html="t('pai.vmset.tips.secret')"
            />
            <i class="icon icon-info" />
          </el-tooltip>
        </template>
        <el-select
          v-model="value.spec.source_parameters.secret"
          :disabled="isEdit"
          filterable
          @input="onSecretChange"
        >
          <el-option
            v-if="!secrets.filter(v=>v.namespace === value.metadata.namespace).filter(v=>value.spec.source_parameters.imageurl.includes(v.dataPreview.replace('https://','').replace('http://',''))).length"
            key="new"
            value="new"
          >
            <router-link to="/pai/c/local/pai.secret/create">
              {{ t('pai.vmset.tips.new_secret') }}
            </router-link>
          </el-option>
          <el-option
            v-for="(item) in secrets.filter(v=>v.namespace === value.metadata.namespace).filter(v=>value.spec.source_parameters.imageurl.includes(v.dataPreview.replace('https://','').replace('http://','')))"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-descriptions-item>
    </el-descriptions>
  </CruResource>
</template>
<style lang="scss" scoped>
.el-input, .el-select, .el-input-number, .el-cascader {
  width: 80%;
}

::v-deep .el-form-item__label {
  line-height: unset;
}

::v-deep .el-select__input {
  border: 0;
  background-color: unset;
}

.left {
  text-align: left
}

::v-deep .el-upload {
  width: 90%;
}

::v-deep .el-upload-dragger {
  width: 100%;
  padding: 20px;
}

::v-deep .banner{
  background-color: var(--warning-banner-bg);
  color: var(--warning);
}
</style>
