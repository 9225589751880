<script>
import { MANAGEMENT, NAMESPACE } from '@shell/config/types';
import { PRODUCT_NAME, PRODUCT_NAME as PAI } from '../config/pai';
import { validateKubernetesName } from '@shell/utils/validators/kubernetes-name';
import UnitInput from '@/shell/components/form/UnitInput';
import { PAI_POD } from '../config/types';
import { POD } from '@/shell/config/types';
import { mapState } from 'vuex';
import { calBestEffortRequestCpu, calBestEffortRequestMemory } from '~/pkg/pai/utils/units';
import { REG_URL } from '~/pkg/pai/config/settings';

export default {
  name:       'ContainerQuickForm',
  components: { UnitInput },
  props:      { onClose: { type: Function, default: null } },
  async fetch() {
    this.namespaceOptions = this.$store.getters[`cluster/all`](NAMESPACE).map(
      (item) => {
        return item.metadata.name;
      }
    );
    this.form.namespace =
      this.namespaceOptions.find((item) => {
        return item === 'default';
      }) || this.namespaceOptions[0];
    try {
      const schemas = this.$store.getters['cluster/schemaFor'](POD);

      const opt = {
        method:  'get',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        REG_URL,
      };
      const data = await schemas.$ctx.dispatch('request', { opt });

      const arr = [];

      for (const key in data) {
        for (const key1 in data[key]) {
          data[key][key1].forEach((item) => {
            arr.push(`${ key }/${ key1 }:${ item }`);
          });
        }
      }
      this.allImages = arr;
    } catch (e) {}
  },
  data() {
    return {
      namespaceOptions: [],
      form:             {
        name:            '',
        namespace:       '',
        containerImages: '',
        cpu:             '1',
        ram:             '2Gi',
      },
      allImages: [],
      radio:     'select',
      errors:    [],
      qosRadio:  'bestEffort',
    };
  },
  computed: {
    ...mapState(['isRancher']),
    createVisible() {
      return (
        this.$store.state['pai-common'].currentModal === 'ContainerQuickForm'
      );
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (
        this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !==
        undefined
      ) {
        return 'local';
      } else {
        return null;
      }
    },
  },
  watch: {
    'form.cpu': {
      deep: true,
      handler() {
        if (this.form.cpu && parseInt(this.form.cpu) < 1) {
          this.form.cpu = 1;
        }
      },
    },
    'form.ram': {
      deep: true,
      handler() {
        if (this.form.ram && parseInt(this.form.ram) < 1) {
          this.form.ram = `${ 1 }Gi`;
        }
      },
    },
  },
  methods: {
    nameValidation() {
      const nameErrors = validateKubernetesName(
        this.form.name || '',
        '',
        this.$store.getters,
        undefined,
        []
      );

      if (nameErrors.length > 0) {
        this.$message.error(
          `${ this.t('pai.vmset.containerName') }${ nameErrors[0].slice(2) }`
        );
      }
    },
    handleClose() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
      if (this.onClose) {
        this.onClose();
      }
    },
    createLocation() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          cluster:  this.currentCluster,
          product:  PAI,
          resource: PAI_POD,
        },
      });
    },
    handleSwitchImage() {
      this.form.containerImages = '';
    },
    async quickCreationPod() {
      try {
        if (/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g.test(this.form.name)) {
          this.$message.error(this.t('pai.edit.tips.prohibitChinese'));

          return;
        }
        // 快速创建容器
        if (!this.form.containerImages || !this.form.cpu || !this.form.ram) {
          this.$message.error(this.t('pai.edit.tips.required'));

          return;
        } else {
          this.nameValidation();
        }
        let resource = {};

        if (this.qosRadio === 'bestEffort') {
          resource = {
            limits: {
              cpu:    this.form.cpu,
              memory: this.form.ram,
            },
            requests: {
              cpu:    `${ calBestEffortRequestCpu(this.form.cpu) }`,
              memory: `${ calBestEffortRequestMemory(this.form.ram) }Gi`,
            },
          };
        } else {
          resource = {
            limits: {
              cpu:    this.form.cpu,
              memory: this.form.ram,
            },
            requests: {
              cpu:    this.form.cpu,
              memory: this.form.ram,
            },
          };
        }
        const pod = await this.$store.dispatch(`cluster/create`, {
          type: POD,
          spec: {
            template: {
              metadata: {
                labels:    { app: this.form.name },
                namespace: this.form.namespace,
                name:      this.form.name,
              },
              spec: {
                containers: [
                  {
                    name:      'container-0',
                    image:     this.form.containerImages,
                    resources: resource,
                  },
                ],
              },
            },
          },
        });

        await pod.save();
        this.$store.dispatch('pai-common/updateState', { currentModal: '' });
        this.$router.push({
          name:   `${ PRODUCT_NAME }-c-cluster-resource-namespace-id`,
          params: {
            product:   PAI,
            cluster:   this.currentCluster,
            resource:  PAI_POD,
            namespace: this.form.namespace,
            id:        this.form.name,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<template>
  <div class="containerForm">
    <el-dialog
      :title="t('pai.apps.container.quickContainerCreation')"
      :visible.sync="createVisible"
      width="550px"
      :before-close="handleClose"
    >
      <el-descriptions
        :column="1"
        :colon="false"
      >
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t("pai.vmset.nameSpace") }}
          </template>
          <el-select
            v-model="form.namespace"
            filterable
            style="width: 378px"
          >
            <el-option
              v-for="item in namespaceOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t("pai.vmset.container") + t("pai.edit.machine.name") }}
          </template>
          <el-input
            v-model="form.name"
            style="width: 378px"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            QoS
          </template>
          <el-radio-group v-model="qosRadio">
            <el-radio
              v-model="qosRadio"
              label="bestEffort"
            >
              {{ t('pai.vmset.lock.share')+'['+t('pai.vmset.lock.bestEffort')+']' }}
            </el-radio>
            <el-radio
              v-model="qosRadio"
              label="guaranteed"
            >
              {{ t('pai.vmset.lock.exclusive')+'['+t('pai.vmset.lock.guaranteed')+']' }}
            </el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>CPU
          </template>
          <UnitInput
            v-model="form.cpu"
            :output-modifier="true"
            :base-unit="t('suffix.cores')"
            :required="true"
            style="width: 364px"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span> {{ t("pai.vmset.ram") }}
          </template>
          <UnitInput
            v-model="form.ram"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            :required="true"
            :min="1"
            style="width: 364px"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t("pai.apps.container.containerMirroring") }}
            <el-tooltip
              :content="t('pai.vmset.tips.quickContainerImage')"
              effect="light"
            >
              <i class="el-icon-question replicasIcon" />
            </el-tooltip>
          </template>
          <div style="margin: 7px 0 0 16px">
            <el-radio-group
              v-model="radio"
              @change="handleSwitchImage"
            >
              <el-radio
                v-model="radio"
                label="select"
              >
                {{ t("pai.apps.container.selectMirror") }}
              </el-radio>
              <el-radio
                v-model="radio"
                label="input"
              >
                {{ t("pai.apps.container.customInput") }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <el-select
            v-if="radio === 'select'"
            v-model="form.containerImages"
            :placeholder="
              t('pai.edit.SelectPlaceholder') +
                t('pai.detail.vmset.operationSystemImage')
            "
            style="width: 378px"
            filterable
          >
            <el-option
              v-for="item in allImages"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <el-input
            v-if="radio === 'input'"
            v-model="form.containerImages"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.detail.vmset.operationSystemImage')
            "
            style="width: 378px"
          />
        </el-descriptions-item>
      </el-descriptions>
      <a
        style="
          display: block;
          margin: 8px 24px 0 0;
          color: #246fa5;
          float: right;
          cursor: pointer;
        "
        @click="createLocation"
      >
        {{ t("pai.apps.vm.enterAdvancedCreation") }}
      </a>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">{{
          t("pai.detail.vmset.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="quickCreationPod"
        >{{
          t("pai.detail.vmset.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.containerForm {
  ::v-deep .el-form-item__label {
    margin-top: 0px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0px;
  }
  ::v-deep .el-descriptions-item__label {
    width: 105px;
  }
  span {
    text-align: left;
    line-height: 36px;
  }
}
</style>
