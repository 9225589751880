<script>
import ResourceTable from '@/pkg/pai/components/ResourceTable';
import Loading from '@shell/components/Loading';

export default {
  name:       'ListMachine',
  components: { ResourceTable, Loading },
  props:      {
    resource: {
      type:     String,
      required: true,
    },
    schema: {
      type:     Object,
      required: true,
    },
    loading: {
      type:     Boolean,
      required: false,
    },
    useQueryParamsForSimpleFiltering: {
      type:    Boolean,
      default: false
    }
  },
  fetch() {
    this.$store.dispatch('management/findAll', {
      type: this.resource,
      opt:  { force: true, watch: true },
    });
  },
  computed: {
    rows() {
      return this.$store.getters['management/all'](this.resource);
    },
  },
  $loadingResources() {
    return { loadIndeterminate: true };
  },
};
</script>

<template>
  <Loading
    v-if="$fetchState.pending"
    :delayed="true"
  />
  <ResourceTable
    v-else
    v-bind="$attrs"
    :rows="rows"
    :namespaced="false"
    :groupable="false"
    :schema="schema"
    key-field="_key"
    :loading="loading"
    :use-query-params-for-simple-filtering="useQueryParamsForSimpleFiltering"
    v-on="$listeners"
  />
</template>
