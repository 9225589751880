<script>
import isEmpty from 'lodash/isEmpty';
import UnitInput from '@shell/components/form/UnitInput';
import { CONTAINER_DEFAULT_RESOURCE_LIMIT } from '@shell/config/labels-annotations';
import { cleanUp } from '@shell/utils/object';
import { _VIEW } from '@shell/config/query-params';

export default {
  components: { UnitInput },

  props: {
    mode: {
      type:    String,
      default: 'create'
    },

    namespace: {
      type:    Object,
      default: null
    },

    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },

    registerBeforeHook: {
      type:    Function,
      default: null
    },

    showTip: {
      type:    Boolean,
      default: true
    },
    setRequestData: {
      type:    Function,
      default: null
    },
    inputDisabled: {
      type:    Boolean,
      default: false,
    },
    qosType: {
      type:    String,
      default: ''
    },
  },

  data() {
    const {
      limitsCpu, limitsMemory, requestsCpu, requestsMemory, limitsGpu
    } = this.value;
    let type = '';

    if (!this.qosType) {
      type = !!requestsCpu && !!requestsMemory ? 'requests' : 'limits';
    } else {
      type = this.qosType === 'guaranteed' ? 'requests' : 'limits';
    }

    return {
      limitsCpu,
      limitsMemory,
      requestsCpu,
      requestsMemory,
      limitsGpu,
      viewMode: _VIEW,
      resMode:  type,
    };
  },

  watch: {
    value() {
      const {
        limitsCpu, limitsMemory, requestsCpu, requestsMemory, limitsGpu
      } = this.value;

      this.limitsCpu = limitsCpu;
      this.limitsMemory = limitsMemory;
      this.requestsCpu = requestsCpu;
      this.requestsMemory = requestsMemory;
      this.limitsGpu = limitsGpu;
    }
  },

  computed: {
    detailTopColumns() {
      return [
        {
          title: this.$store.getters['i18n/t']('generic.created'),
          name:  'created'
        },
      ];
    },
  },

  created() {
    if (this?.namespace?.id) {
      this.initLimits();
    }

    if (this.registerBeforeHook) {
      this.registerBeforeHook(this.updateBeforeSave);
    }
  },

  methods: {
    updateLimits() {
      const { limitsGpu } = this;
      let {
        limitsCpu,
        limitsMemory,
        requestsCpu,
        requestsMemory,
      } = this;

      if (this.resMode === 'requests') {
        limitsCpu = null;
        limitsMemory = null;
      } else {
        requestsCpu = null;
        requestsMemory = null;
      }

      this.$emit('input', cleanUp({
        limitsCpu,
        limitsMemory,
        requestsCpu,
        limitsGpu,
        requestsMemory
      }));
    },

    updateBeforeSave(value) {
      const {
        limitsCpu,
        limitsMemory,
        requestsCpu,
        requestsMemory,
        limitsGpu
      } = this;
      const namespace = this.namespace; // no deep copy in destructure proxy yet

      const out = cleanUp({
        limitsCpu,
        limitsMemory,
        requestsCpu,
        limitsGpu,
        requestsMemory
      });

      if (namespace) {
        namespace.setAnnotation(CONTAINER_DEFAULT_RESOURCE_LIMIT, JSON.stringify(out));
      }
    },

    initLimits() {
      const namespace = this.namespace;
      const defaults = namespace?.metadata?.annotations[CONTAINER_DEFAULT_RESOURCE_LIMIT];

      // Ember UI can set the defaults to the string literal 'null'
      if (!isEmpty(defaults) && defaults !== 'null') {
        const {
          limitsCpu,
          limitsMemory,
          requestsCpu,
          requestsMemory,
          limitsGpu
        } = JSON.parse(defaults);

        this.limitsCpu = limitsCpu;
        this.limitsMemory = limitsMemory;
        this.requestsCpu = requestsCpu;
        this.requestsMemory = requestsMemory;
        this.limitsGpu = limitsGpu;
      }
    },
  }

};
</script>

<template>
  <div>
    <div class="row">
      <div
        v-if="showTip"
        class="col span-12"
      >
        <p class="helper-text mb-10">
          <t
            v-if="mode === viewMode"
            k="containerResourceLimit.helpTextDetail"
          />
          <t
            v-else
            k="containerResourceLimit.helpText"
          />
        </p>
      </div>
    </div>
    <el-descriptions
      title=""
      direction="vertical"
      :column="2"
      :colon="false"
    >
      <el-descriptions-item>
        <template>
          QoS: &emsp;
          <el-radio-group
            v-model="resMode"
            :disabled="inputDisabled"
            @change="setRequestData(resMode)"
          >
            <el-radio
              label="limits"
            >
              {{ t('pai.vmset.lock.share')+'['+t('pai.vmset.lock.bestEffort')+']' }}
              <el-tooltip
                :content="t('pai.vmset.lock.shareTips')"
                effect="light"
              >
                <i
                  class="icon icon-info"
                  style="color: #606266;margin-left: 3px"
                />
              </el-tooltip>
            </el-radio>
            <el-radio
              label="requests"
            >
              {{ t('pai.vmset.lock.exclusive')+'['+t('pai.vmset.lock.guaranteed')+']' }}
              <el-tooltip
                :content="t('pai.vmset.lock.exclusiveTips')"
                effect="light"
              >
                <i
                  class="icon icon-info"
                  style="color: #606266;margin-left: 3px"
                />
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </template>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title=""
      direction="vertical"
      :column="2"
      :colon="false"
    >
      <el-descriptions-item>
        <template
          slot="label"
        >
          <div>
            <span style="color: red; margin-right: 2px;">*</span>{{ t('pai.vmset.lock.cpu') }}
          </div>
        </template>
        <div>
          <UnitInput
            v-if="resMode === 'requests'"
            v-model="requestsCpu"
            :placeholder="t('containerResourceLimit.cpuPlaceholder')"
            label=""
            :mode="mode"
            :output-modifier="true"
            :base-unit="t('suffix.cores')"
            style="width: 60%"
            @input="updateLimits"
          />
          <UnitInput
            v-else
            v-model="limitsCpu"
            :placeholder="t('containerResourceLimit.cpuPlaceholder')"
            label=""
            :mode="mode"
            :output-modifier="true"
            :base-unit="t('suffix.cores')"
            style="width: 60%"
            @input="updateLimits"
          />
        </div>
      </el-descriptions-item>

      <el-descriptions-item>
        <template
          slot="label"
        >
          <div>
            <span style="color: red;margin-right: 2px;">*</span>{{ t('pai.vmset.lock.ram') }}
          </div>
        </template>
        <div>
          <UnitInput
            v-if="resMode === 'requests'"
            v-model="requestsMemory"
            :placeholder="t('containerResourceLimit.memPlaceholder')"
            label=""
            :mode="mode"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            style="width: 60%"
            @input="updateLimits"
          />
          <UnitInput
            v-else
            v-model="limitsMemory"
            :placeholder="t('containerResourceLimit.memPlaceholder')"
            label=""
            :mode="mode"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            style="width: 60%"
            @input="updateLimits"
          />
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
