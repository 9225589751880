import SteveModel from '@shell/plugins/steve/steve-class';
import { PVC } from '@shell/config/types';
import { ALIAS, PVC_LABELS } from '~/pkg/pai/config/labels-annotations';
import { PAI_RESOURCES } from '~/pkg/pai/config/types';
import { COMMAND_HASH_MPA } from '../config/settings';
import { PRODUCT_NAME } from '../config/pai';
import StarMixin from '~/pkg/pai/mixins/star';
import { Message } from 'element-ui';

export default class extends StarMixin(SteveModel) {
  async remove() {
    // 删除镜像前判断是否有云主机或pvc挂载了该镜像
    if (this.usedVms.length) {
      throw new Error(this.t('pai.vmset.tips.image.usedVm'));
    } else if (this.usedPvcs.length) {
      throw new Error(this.t('pai.vmset.tips.image.usedPvc'));
    } else {
      // 删除镜像的同时删除对应的backingimage
      await this._remove();
      const backingImageName = `${ this.namespace }-${ this.name }`;
      const opt = {
        url:    `/k8s/clusters/local/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages/${ backingImageName }?action=backingImageCleanup`,
        method: 'delete',
      };

      try {
        await this.$dispatch('request', { opt } );
      } catch (e) {
        console.log(e);
      }
    }
  }

  async export() {
    const backingImageName = `${ this.namespace }-${ this.name }`;
    const opt = {
      url:    `/k8s/clusters/local/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages`,
      method: 'get',
    };

    try {
      const list = await this.$dispatch('request', { opt } );
      const backingImage = list.data.find(v => v.name === backingImageName);

      if (backingImage) {
        const diskFileStatus = Object.values(backingImage.diskFileStatusMap).map(v => v.state);

        if (diskFileStatus.includes('ready')) {
          window.location.href = `${ window.location.origin }/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages/${ backingImageName }/download`;
        } else {
          Message.warning(this.t('pai.vmset.tips.image.noReady'));
        }
      } else {
        Message.warning(this.t('pai.vmset.tips.image.noBackingImage'));
      }
    } catch (e) {
      console.log(e);
    }
  }

  get usedPvcs() {
    const pvcs = this.$rootGetters[`cluster/all`](PVC);
    const result = [];

    pvcs.forEach((v) => {
      const sc = v.spec.storageClassName;

      if (sc === `${ this.namespace }-${ this.name }`) {
        result.push(v);
      }
    });

    return result;
  }

  get usedVms() {
    const pvcs = this.usedPvcs;
    const vms = this.$rootGetters[`cluster/all`](PAI_RESOURCES.VMSET);
    const result = [];

    pvcs.forEach((v) => {
      if (v.labels && v.labels[PVC_LABELS.MOUNT_VM]) {
        const name = v.labels[PVC_LABELS.MOUNT_VM];
        const vm = vms.find(v => v.name === name);

        if (vm) {
          result.push(vm);
        }
      }
    });

    return result;
  }

  get doneOverride() {
    return {
      name:   'pai-c-cluster-apps-charts',
      params: { product: PRODUCT_NAME, cluster: this.$rootGetters['clusterId'] },
      hash:   COMMAND_HASH_MPA.vm
    };
  }

  get alias() {
    return (this.spec && this.spec.alias) ? this.spec.alias : '';
  }

  get nameDisplay() {
    return this.alias ? this.alias : super.nameDisplay;
  }
}
