<script>
export default { layout: 'pai/default' };
</script>
<template>
  <main class="main-layout">
    <div
      class="chart-content"
    >
      <div
        class="wrapper"
      >
        <div
          class="chart-readmes"
        >
          <div
            class="markdown md md-desc"
          >
            <h2>
              {{ t('charts.paistore.paiStoreName') }}
            </h2>
            <p>{{ t('charts.paistore.introduce') }}</p>
            <h2 style="margin-top:30px">
              {{ t('charts.paistore.productFeatures.title') }}:
            </h2>
            <ul>
              <li>{{ t('charts.paistore.productFeatures.lightweight') }}</li>
              <li>{{ t('charts.paistore.productFeatures.reliable') }}</li>
              <li>{{ t('charts.paistore.productFeatures.enterpriseLevel') }}</li>
              <li>{{ t('charts.paistore.productFeatures.automation') }}</li>
              <li>{{ t('charts.paistore.productFeatures.copy') }}</li>
              <li>{{ t('charts.paistore.productFeatures.architecture') }}</li>
              <li>{{ t('charts.paistore.productFeatures.easyToUse') }}</li>
            </ul>
            <h1 class="text-center mt-50">
              <a
                href="/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/#/dashboard"
                target="_blank"
                rel="noopener"
              >{{ t('charts.paistore.productFeatures.enterManage') }}</a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
