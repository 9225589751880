<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import SectionTitle from '../components/form/SectionTitle';
import { LabeledInput } from '@components/Form/LabeledInput';
import LabeledSelect from '@/shell/components/form/LabeledSelect';
import CruResource from '@shell/components/CruResource';
import LabeledRadioGroup from '../components/form/LabeledRadioGroup';
import { _CLONE, _CREATE, _EDIT } from '@shell/config/query-params';
import { MACHINE_ANNOTATIONS } from '../config/labels-annotations';
import { kparamsToArr } from '../utils/string';
import { set } from '@shell/utils/object';
import { PAI_PLATFORM_SETTING_TYPES } from '../config/types';

export default {
  components: {
    LabeledRadioGroup,
    CruResource,
    LabeledInput,
    LabeledSelect,
    SectionTitle,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
    realMode: {
      type:    String,
      default: 'edit'
    }
  },
  async fetch() {
    this.gatewayData = await this.$store.dispatch('management/findAll', { type: PAI_PLATFORM_SETTING_TYPES.GATEWAY });
    this.nodeDatas = await this.$store.dispatch('cluster/findAll', { type: 'node' });
  },
  data() {
    let kparams = [];

    if (this.value?.spec?.kparams) {
      kparams = kparamsToArr(this.value.spec.kparams);
    }

    return {
      kparams,
      tip:         '',
      gatewayData: [],
      MACHINE_ANNOTATIONS,
      nodeDatas:   [],
      nodesTimer:  null
    };
  },
  computed: {
    _CREATE() {
      return _CREATE;
    },
    _CLONE() {
      return _CLONE;
    },
    _EDIT() {
      return _EDIT;
    },
    nodeNameList() {
      const nodeNameList = this.nodes?.map((steveModel) => steveModel.metadata.name);

      nodeNameList?.splice(nodeNameList?.indexOf(this.value.metadata.name), 1);

      return nodeNameList;
    },
    clusterList() {
      return this.gatewayData.map((item) => item.metadata.name);
    },
    osList() {
      return this.$store.state['pai-common'].osList?.map((v) => {
        return ({
          label: v.split('/')[v.split('/').length - 1],
          value: v
        });
      });
    },
    cluster() {
      return this.value.metadata.labels['com.tdology.gateway'];
    },
    isEditable() {
      return this.realMode === _CREATE || this.realMode === _CLONE || (this.realMode === _EDIT && this.value.metadata.annotations && this.value.metadata.annotations[MACHINE_ANNOTATIONS.ACTION] === 'provision');
    },
    nodes() {
      return this.$store.getters['management/all'](PAI_PLATFORM_SETTING_TYPES.MACHINE);
    },
    newNameDisable() {
      return !!this.nodeDatas.find((node) => this.value.metadata.name === node.metadata?.name);
    }
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    selectionChanged(e) {
      if (e === 'mode=install' && this.value.status.mode === 'mode=disk') {
        this.tip = this.t('pai.edit.machine.installTip');
      } else {
        this.tip = null;
      }
    },
    async saveScan(buttonCb) {
      for (let i = 0; i < this.nodes.length; i++) {
        if (this.realMode === _CREATE) {
          if (this.nodes[i].metadata.name === this.value.metadata.name) {
            this.$message.error(this.t('pai.detail.vmset.duplicateNames'));
            buttonCb(false);

            return;
          }
        } else if (this.nodes[i].metadata.name === this.value.metadata.annotations['picloud/newname']) {
          this.$message.error(this.t('pai.detail.vmset.duplicateNames'));
          buttonCb(false);

          return;
        }
      }
      if (this.isEditable) {
        if (!this.value.metadata.name || !this.value.spec.ip || !this.value.metadata?.labels['com.tdology.gateway'] || !this.value.spec.os || !this.value.spec.arch) {
          this.$message.error(this.t('pai.edit.tips.required'));
          buttonCb(false);

          return;
        } else if (!/^(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])$/.test(this.value.spec.ip)) {
          this.$message.error(this.t('pai.edit.tips.ipError'));
          buttonCb(false);

          return;
        } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.metadata.name) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.metadata.annotations['picloud/alias']) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.install.device) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.arch)) {
          this.$message.error(this.t('pai.edit.tips.specialCharacters'));
          buttonCb(false);

          return;
        }
        this.tip = this.t('pai.edit.machine.provisionTip');
      }
      try {
        await this.save(buttonCb);
        clearTimeout(this.nodesTimer);
        this.nodesTimer = await setTimeout(async() => {
          // 检查启动模式是否修改成功
          const machine = await this.$store.dispatch('cluster/find', {
            type: PAI_PLATFORM_SETTING_TYPES.MACHINE,
            id:   this.value.id,
            opt:  { force: true },
          });

          if (this.tip) {
            this.$message({
              type:    'warning',
              message: this.tip
            });
          } else if (!this.isEditable && this.value?.metadata?.annotations[MACHINE_ANNOTATIONS.ACTION] && machine?.status?.mode !== this.value?.metadata?.annotations[MACHINE_ANNOTATIONS.ACTION]) {
            this.$message({
              type:    'warning',
              message: this.t('pai.edit.machine.bootMode') + this.t('pai.lb.tip.fail')
            });
          } else {
            this.$message.success(this.t('pai.edit.tips.success'));
          }
        }, 2000);
      } catch (e) {
        console.log(e);
        buttonCb(false);
      }
    },
  },
  watch: {
    cluster() {
      this.$store.dispatch('pai-common/getOs', this.value.metadata.labels['com.tdology.gateway']);
    },
    kparams: {
      handler(nue) {
        if (nue.length) {
          const kparams = nue.filter((v) => !!v.trim()).map((v) => v.trim()).join(' ');

          set(this.value, 'spec.kparams', kparams);
        } else if (this.value?.spec?.kparams) {
          this.$delete(this.value.spec, 'kparams');
        }
      },
      deep:      true,
      immediate: true,
    },
    osList(val) {
      if (val) {
        this.$set(this.value.spec, 'os', val.length !== 0 ? this.value?.spec?.os : '');
      }
    }
  },
  created() {
    // Add fields missing when create or clone
    const spec = {
      ip: '', os: '', install: { device: '' }, cloudconfig: { k3os: {} }
    };

    if (!this.value.spec && this.realMode === _CREATE) {
      this.$set(this.value, 'spec', spec);
      this.$set(this.value.metadata.labels, 'com.tdology.gateway', '');
    }
    if (!this.value.status) {
      this.$set(this.value, 'status', '');
    }
    for (const key in spec) {
      if (!this.value.spec[key]) {
        this.value.spec[key] = spec[key];
      }
    }
    // query os list
    if (this.realMode !== _CREATE) {
      if (this.value.metadata.labels['com.tdology.gateway']) {
        this.$store.dispatch('pai-common/getOs', this.value.metadata.labels['com.tdology.gateway']);
      }
    }
    if (this.value.metadata.namespace) {
      this.$set(this.value.metadata, 'namespace', 'picloud-system');
    }
    // default setting
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/action']) {
      this.$set(this.value.metadata.annotations, 'picloud/action', 'provision');
    }
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/role']) {
      this.$set(this.value.metadata.annotations, 'picloud/role', 'agent');
    }
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/seednode']) {
      this.$set(this.value.metadata.annotations, 'picloud/seednode', 'false');
    }
  },
  beforeDestroy() {
    this.$store.commit('pai-common/getOs', []);
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="saveScan"
    @cancel="done"
  >
    <div v-if="realMode===_CLONE">
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <h4>ANNOTATIONS</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.annotations['picloud/tmpl']"
            :label="t('pai.edit.machine.templateNode')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
            :tooltip="t('pai.edit.machine.templateNodeTooltip')"
            :append-to-body="false"
            :options="nodeNameList"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.spec.os"
            :required="true"
            :label="t('pai.edit.machine.operatingSystem')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
            :append-to-body="false"
            :options="osList"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/role']"
            name="picloud/role"
            :label="t('pai.edit.machine.nodeRole')"
            :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
            :row="true"
            :required="true"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/seednode']"
            name="picloud/seednode"
            :label="t('pai.edit.machine.seedNode')"
            :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
            :required="true"
            :row="true"
            :tooltip="t('pai.edit.machine.firstNode')"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.install.device"
            type="text"
            :label="t('pai.edit.machine.systemDisk')"
            :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
            :mode="mode"
          />
        </div>
      </div>
    </div>
    <div v-else-if="realMode===_CREATE">
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            :append-to-body="false"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.labels['com.tdology.gateway']"
            :label="t('pai.edit.gateway.name')"
            :placeholder="t('pai.edit.placeholder') + t('pai.edit.gateway.name')"
            :append-to-body="false"
            :options="clusterList"
            :required="true"
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <h4>ANNOTATIONS</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.annotations['picloud/tmpl']"
            :label="t('pai.edit.machine.templateNode')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
            :tooltip="t('pai.edit.machine.templateNodeTooltip')"
            :append-to-body="false"
            :options="nodeNameList"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.spec.os"
            :required="true"
            :label="t('pai.edit.machine.operatingSystem')+t('pai.edit.machine.byClusterShow')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
            :append-to-body="false"
            :options="osList"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/role']"
            name="picloud/role"
            :label="t('pai.edit.machine.nodeRole')"
            :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
            :row="true"
            :required="true"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/seednode']"
            name="picloud/seednode"
            :label="t('pai.edit.machine.seedNode')"
            :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
            :required="true"
            :row="true"
            :tooltip="t('pai.edit.machine.firstNode')"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.install.device"
            type="text"
            :label="t('pai.edit.machine.systemDisk')"
            :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <h4>SPEC</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.arch"
            type="text"
            :required="true"
            label="ARCH"
            :mode="mode"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            mode="view"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            mode="view"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20">
        <LabeledRadioGroup
          v-model.trim="value.metadata.annotations[MACHINE_ANNOTATIONS.ACTION]"
          :default-value="value.status.mode"
          name="picloud/action"
          :label="t('pai.edit.machine.nodeOperation')"
          :options="[{label:t('pai.edit.machine.modifyConfiguration'),value:'provision'},{label:t('pai.edit.machine.liveMode'),value:'mode=live'},
                     {label:t('pai.edit.machine.installationSystem'),value:'mode=install'},{label:t('pai.edit.machine.diskBoot'),value:'mode=disk'},
                     {label:t('pai.edit.machine.emergencyRepair'),value:'fallback_mode=live'}]"
          :row="true"
          :mode="mode"
          @input="selectionChanged($event)"
        />
      </div>
      <div v-if="value.metadata.annotations[MACHINE_ANNOTATIONS.ACTION] === 'provision'">
        <div class="mb-20 row">
          <div class="col span-6">
            <LabeledInput
              v-model.trim="value.metadata.annotations['picloud/newname']"
              type="text"
              :label="t('pai.edit.machine.newName')"
              :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.newName')"
              :mode="mode"
              :disabled="newNameDisable"
            />
          </div>
          <div class="col span-6">
            <LabeledSelect
              v-model.trim="value.metadata.annotations['picloud/tmpl']"
              :label="t('pai.edit.machine.templateNode')"
              :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
              :tooltip="t('pai.edit.machine.templateNodeTooltip')"
              :append-to-body="false"
              :options="nodeNameList"
              :mode="mode"
            />
          </div>
        </div>
        <div class="mb-20 row">
          <div class="col span-6">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/role']"
              name="picloud/role"
              :label="t('pai.edit.machine.nodeRole')"
              :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
              :row="true"
              :required="true"
              :mode="mode"
            />
          </div>
          <div class="col span-6">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/seednode']"
              name="picloud/seednode"
              :label="t('pai.edit.machine.seedNode')"
              :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
              :required="true"
              :row="true"
              :tooltip="t('pai.edit.machine.firstNode')"
              :mode="mode"
            />
          </div>
        </div>
        <div class="mb-20 row">
          <div class="col span-6">
            <div class="mb-20">
              <LabeledSelect
                v-model.trim="value.spec.os"
                :required="true"
                :label="t('pai.edit.machine.operatingSystem')"
                :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
                :append-to-body="false"
                :options="osList"
                :mode="mode"
              />
            </div>
          </div>
          <div class="col span-6">
            <div class="mb-20">
              <LabeledInput
                v-model.trim="value.spec.install.device"
                type="text"
                :label="t('pai.edit.machine.systemDisk')"
                :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
                :mode="mode"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isEditable">
      <div>
        <SectionTitle
          :value="t('pai.edit.machine.kparams')"
          class="row mt-10 mb-10"
        >
          <el-tooltip
            :content="t('pai.edit.machine.kparamsTip')"
            effect="light"
          >
            <i class="icon icon-info" />
          </el-tooltip>
        </SectionTitle>
      </div>
      <div class="row mt-10 mb-10">
        <button
          type="button"
          class="btn role-primary"
          style="margin-right: 10px"
          :disabled="!isEditable"
          @click="kparams.push('')"
        >
          <i class="el-icon-plus" />
          {{ t('generic.add') }}
        </button>
        <button
          type="button"
          class="btn bg-error"
          :disabled="!kparams.length || !isEditable"
          @click="kparams=[]"
        >
          <i class="el-icon-delete" />
          {{ t('pai.labels.removeAll') }}
        </button>
      </div>
      <div
        v-for="(item,i) in kparams"
        :key="item+i"
        class="mb-20 row"
      >
        <div class="col span-6">
          <LabeledInput
            :value="item"
            type="text"
            placeholder="isolcpus=1,2,10-20"
            :mode="mode"
            @input="kparams[i]=$event"
            @blur="$set(kparams,i,kparams[i])"
          />
        </div>
        <el-button
          type="text"
          :disabled="!isEditable"
          @click="kparams.splice(i,1)"
        >
          {{ t('pai.labels.remove') }}
        </el-button>
      </div>
    </template>
  </CruResource>
</template>

<style lang="scss" scoped>
.v-select, .v-select *{
  width: 100% !important;
}
.el-button--text,.el-button--text:focus{
  border: unset;
  color: var(--primary);
}
BUTTON:focus{
  box-shadow:unset;
}
</style>
