<script>
import day from 'dayjs';
import { mapGetters } from 'vuex';
import { PAI_RESOURCES } from '../../../config/types';
export default {
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
  },
  data() {
    const params = this.$route.params;

    return {
      params, tableData: [], restore: [], filterValue: ''
    };
  },
  async fetch() {
    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.RESTORE)) {
      this.restore = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.RESTORE });
    }
  },
  computed: {
    ...mapGetters([{ t: 'i18n/t' }, 'isRancher']),
    newTableData() {
      const dateFormat = 'YYYY-MM-DD';
      const timeFormat = 'h:mm:ss';
      const tableData = [];

      this.restore.map((item, index) => {
        if (item.spec?.labelSelector?.matchLabels['com.tdology.virt.vmsets'] === this.value.metadata.name) {
          tableData.push({
            name:               item.metadata?.name,
            displayName:        item.metadata?.labels?.displayName,
            projectDisplayName: item.projectDisplayName,
            namespace:          item.spec.includedNamespaces?.length > 0 && item.spec.includedNamespaces[0],
            status:             item.status?.phase,
            creator:            item.metadata?.annotations?.createby,
            createTime:         day(item.metadata.creationTimestamp).format(`${ dateFormat } ${ timeFormat }`),
            startTime:          day(item.status?.startTimestamp).format(`${ dateFormat } ${ timeFormat }`),
          });
        }
      });

      return tableData;
    },
    restoreStatus() {
      return [
        {
          name: 'Active', label: this.t('pai.detail.vmset.tab.backUpManagement.label.newCreate'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.activeTip')
        },
        {
          name: 'New', label: this.t('pai.detail.vmset.tab.backUpManagement.label.newCreate'), tip: this.t('pai.detail.vmset.tab.restoreRecords.tips.newTip')
        },
        {
          name: 'FailedValidation', label: this.t('pai.detail.vmset.tab.backUpManagement.label.validationFailed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.validationFailedTip', { key: this.t('pai.detail.vmset.restore') })
        },
        {
          name: 'InProgress', label: this.t('pai.detail.vmset.tab.backUpManagement.label.inProgress'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.inProgressTip', { key: this.t('pai.detail.vmset.restore') })
        },
        {
          name: 'WaitingForPluginOperations', label: this.t('pai.detail.vmset.tab.backUpManagement.label.waitingForPluginOperations'), tip: this.t('pai.detail.vmset.tab.restoreRecords.tips.waitingForPluginOperationsTip')
        },
        {
          name: 'WaitingForPluginOperationsPartiallyFailed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.waitingForPluginOperationsPartiallyFailed'), tip: this.t('pai.detail.vmset.tab.restoreRecords.tips.waitingForPluginOperationsPartiallyFailed')
        },
        {
          name: 'Completed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.completed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.completedTip', { key: this.t('pai.detail.vmset.restore') })
        },
        {
          name: 'PartiallyFailed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.partiallyFailed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.partiallyFailedTip', { key: this.t('pai.detail.vmset.restore') })
        },
        {
          name: 'Failed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.failed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.failedTip', { key: this.t('pai.detail.vmset.restore') })
        },
      ];
    },
  },
  methods: {
    deleteRestore(value) {
      this.$confirm(this.t('pai.detail.vmset.confirmDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(() => {
        const index = this.restore.findIndex(i => value.name === i.metadata.name);

        this.restore[index].remove();
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteSuccess')
        });
      }).catch(() => {
        this.$message({
          type:    'error',
          message: this.t('pai.detail.vmset.cancelDelete')
        });
      });
    },
  }
};
</script>

<template>
  <el-card style="margin:10px">
    <el-row>
      <el-col :span="24">
        <div style="display: flex; justify-content: space-between;">
          <el-input
            v-model="filterValue"
            :placeholder="t('pai.detail.vmset.filter')"
            prefix-icon="el-icon-search"
            size="small"
          />
        </div>
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? newTableData.filter(item => item.displayName.includes(filterValue)):newTableData"
          class="restoreTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
            width="50"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.restoreRecords.name')"
            width="170"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.displayName"
                effect="light"
              >
                <span
                  style="width: 180px; overflow:hidden; white-space: nowrap; text-overflow: ellipsis;"
                >{{ scope.row.displayName }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isRancher"
            prop="projectDisplayName"
            :label="t('pai.detail.vmset.tab.backUpManagement.projectName')"
          />
          <el-table-column
            prop="namespace"
            :label="t('pai.detail.vmset.tab.backUpManagement.namespaceName')"
          />
          <el-table-column
            :label="t('pai.detail.vmset.status')"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in restoreStatus"
                :key="index"
              >
                <span v-if="item.name === scope.row.status">
                  {{ item.label }}
                  <el-tooltip
                    :content="item.tip"
                    placement="top"
                    effect="light"
                  >
                    <img src="@pkg/pai/assets/images/overview/tooltips.svg">
                  </el-tooltip>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="creator"
            :label="t('pai.detail.vmset.tab.restoreRecords.creator')"
          />
          <el-table-column
            prop="createTime"
            :label="t('pai.detail.vmset.tab.restoreRecords.createTime')"
            width="180"
          />
          <el-table-column
            prop="startTime"
            :label="t('pai.detail.vmset.startTime')"
            width="180"
          />
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="deleteRestore(scope.row)"
              >
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.restoreTable{
  padding: 0 20px;
}
.el-input {
  border: 2px solid #5084AA;
  border-radius: 5px;
  width: 215px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
</style>
