import { render, staticRenderFns } from "./csi.aliyun.com.nodelocalstorageinitconfig.vue?vue&type=template&id=5dad84ec&scoped=true"
import script from "./csi.aliyun.com.nodelocalstorageinitconfig.vue?vue&type=script&lang=js"
export * from "./csi.aliyun.com.nodelocalstorageinitconfig.vue?vue&type=script&lang=js"
import style0 from "./csi.aliyun.com.nodelocalstorageinitconfig.vue?vue&type=style&index=0&id=5dad84ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dad84ec",
  null
  
)

export default component.exports