<script>
import config, { VM_POWER_STATES, VM_POWER_STATES_ENUM } from '../plugins/pai-resource-class';
import { PRODUCT_NAME as PAI } from '../config/pai';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { mapGetters } from 'vuex';
import { convertCpuToCore, convertUnitToG } from '../utils/units';
import { omitString } from '../utils/string';
import { POD, PVC } from '@shell/config/types';
import { ALIAS, GUEST_AGENT_ANNOTATIONS, IMAGE, VMSET_ANNOTATIONS } from '../config/labels-annotations';
import { _EDIT } from '@shell/config/query-params';
import windowsIcon from '../assets/vmImage/windows.png';
import linuxIcon from '../assets/vmImage/linux.png';
import { GUEST_COMMANDS, sendCommandsToPodFirstContainerWithCallback } from '../utils/guest-command';

export default {
  props: {
    value: {
      type:     Object,
      required: true,
    },
  },
  async fetch() {
    this.diskData = `${ this.diskSize }G`;
  },
  data() {
    const cpuRamResource = this.value.spec?.template?.spec?.containers?.[0]?.resources;
    const cpuData = cpuRamResource?.limits ? convertCpuToCore(cpuRamResource?.limits?.cpu ? cpuRamResource?.limits?.cpu : 0) + this.t('suffix.cores') : convertCpuToCore(cpuRamResource?.requests?.cpu ? cpuRamResource?.requests.cpu : 0) + this.t('suffix.cores');
    const ramData = cpuRamResource?.limits ? `${ convertUnitToG(cpuRamResource?.limits?.memory ? cpuRamResource?.limits?.memory : 0) }G` : `${ convertUnitToG(cpuRamResource?.requests?.memory ? cpuRamResource?.requests?.memory : 0) }G`;
    const alias = (this.value.metadata.annotations && this.value.metadata.annotations[ALIAS]) ? this.value.metadata.annotations[ALIAS] : '';
    const imageAlias = (this.value.metadata.annotations[VMSET_ANNOTATIONS.IMAGE_ALIAS] ? this.value.metadata.annotations[VMSET_ANNOTATIONS.IMAGE_ALIAS] : '');
    let currentPod = {};
    const vmPods = [];

    if (this.value.status && this.value.status.instances) {
      const { instances = {} } = this.value.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        vmPods.push({
          name:   key,
          status: instances[key].status,
        });
      });
    }
    if (vmPods.length > 0) {
      currentPod = vmPods[0];
    }

    return {
      alias,
      imageAlias,
      loadImgSrc:     `this.src = "${ require('../assets/images/home/empty.svg') }"`,
      powerState:     this.value.powerState,
      cpuData,
      ramData,
      diskData:       '',
      cpuUsedRate:    0,
      ramUsedRate:    0,
      diskUsedRate:   0,
      currentPod,
      guestInstalled: false,
    };
  },
  computed: {
    ...mapGetters(['currentCluster']),
    isPowerOn() {
      return this.value.powerState === VM_POWER_STATES_ENUM.On;
    },
    stateLabel() {
      return this.t(VM_POWER_STATES[this.value.powerState].labelKey);
    },
    stateClassName() {
      return VM_POWER_STATES[this.value.powerState].className;
    },
    currentClusterId() {
      return this.currentCluster?.id || 'local';
    },
    osImg() {
      if (this.value.annotations && this.value.annotations[IMAGE.LOGO]) {
        const logoName = this.value.annotations[IMAGE.LOGO];

        try {
          const logo = require(`../assets/images/imageLogo/${ logoName }`);

          if (logo) {
            return logo;
          }
        } catch (e) {}
      }

      return this.value.spec?.os?.includes('windows') ? windowsIcon : linuxIcon;
    },
    vmPods() {
      const vmPods = [];

      if (this.value.status && this.value.status.instances) {
        const { instances = {} } = this.value.status;
        const keys = Object.keys(instances);

        keys.forEach((key) => {
          vmPods.push({
            name:   key,
            status: instances[key].status,
          });
        });
      }

      return vmPods;
    },
    isRunning() {
      return this.currentPod.status === VM_POWER_STATES_ENUM.Running;
    },
    diskSize() {
      let diskData = 0;

      if (this.value.spec.volumeClaimTemplates?.length > 0) {
        this.value.spec.volumeClaimTemplates.forEach((v) => {
          if (v.spec?.resources?.requests?.storage) {
            diskData += convertUnitToG(v.spec.resources.requests.storage);
          }
        });
      }
      if (this.value.spec.volumes?.length > 0) {
        for (const v of this.value.spec.volumes) {
          const name = v.name;
          const disk = this.$store.getters['cluster/all'](PVC).find((volume) => volume.name === name);

          if (disk && disk.spec?.resources?.requests?.storage) {
            diskData += convertUnitToG(disk.spec.resources.requests.storage);
          }
        }
      }

      return diskData;
    }
  },
  methods: {
    omitString,
    initGuestInstalled() {
      if (this.currentPod && this.isRunning) {
        this.$store.dispatch('cluster/find', {
          type: POD,
          id:   `${ this.value.namespace }/${ this.currentPod.name }`,
        }).then((pod) => {
          if (pod.metadata.annotations[GUEST_AGENT_ANNOTATIONS.INSTALL] === undefined) {
            sendCommandsToPodFirstContainerWithCallback(this.currentCluster?.id, pod, GUEST_COMMANDS.ping, (result) => {
              if (result.success && result.data && result.data['return']) {
                pod.setAnnotation(GUEST_AGENT_ANNOTATIONS.INSTALL, true);
                this.guestInstalled = true;
                const data = { metadata: { annotations: { [GUEST_AGENT_ANNOTATIONS.INSTALL]: 'true' } } };

                pod.patch(data, {}, true, true);
              }
            });
          } else {
            this.guestInstalled = pod.metadata.annotations[GUEST_AGENT_ANNOTATIONS.INSTALL] === 'true';
          }
        });
      }
    },
    async onActionStar() {
      this.value = await this.value.forceFetch();
      this.value.setStar();

      this.$emit('changeCollection');
    },
    initUsedRate() {
      if (this.currentPod && this.currentPod.name && this.isRunning) {
        this.$store.dispatch('pai-grafana/getCpu', {
          cluster:   this.currentClusterId,
          namespace: this.value.metadata.namespace,
          podName:   this.currentPod.name,
        })
          .then((value) => {
            this.cpuUsedRate = value || 0;
          });
        this.$store.dispatch('pai-grafana/getRamDashboard', {
          cluster:   this.currentClusterId,
          namespace: this.value.metadata.namespace,
          podName:   this.currentPod.name,
        })
          .then((value) => {
            this.ramUsedRate = value || 0;
          });
        this.$store.dispatch('pai-grafana/getDisk', {
          cluster:   this.currentClusterId,
          namespace: this.value.metadata.namespace,
          podName:   this.currentPod.name,
        })
          .then((value) => {
            this.diskUsedRate = value || 0;
          });
      }
    },
    vmDetail() {
      const editLocation = {
        name:   `${ PAI }-c-cluster-tmpl-resource-namespace-id`,
        params: {
          id:        this.value.name,
          product:   PAI,
          resource:  PAI_RESOURCES.VMSET,
          cluster:   this.currentClusterId,
          namespace: this.value.namespace,
        },
        query: { mode: _EDIT, tmpl: 'true' }
      };

      this.$router.push(editLocation);
    },
    vmAdd() {
      const params = { ...this.$route.params };
      const createLocation = {
        name:  `${ PAI }-c-cluster-resource-create`,
        params,
        query: { vmId: this.value.id }
      };

      this.$router.push(createLocation);
    },
    onDelete() {
      this.$confirm(this.t('pai.vmset.tips.deleteConfirmTmpl'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      }).then(async() => {
        try {
          this.value.setAnnotation('removed', 'true');
          await this.value._save();
          await this.value.forceRemove();
          this.$emit('update');
          await this.$message({
            type:    'success',
            message: this.t('pai.labels.success'),
          });
        } catch (e) {
          this.$message({
            type:    'warning',
            message: e.message,
          });
        }
      }).catch(() => {
      });
    }
  },
  watch: {
    vmPods: {
      handler(nue, old) {
        if (JSON.stringify(nue) !== JSON.stringify(old) && nue.length > 0) {
          this.currentPod = nue[0];
        }
      },
      deep:      true,
      immediate: true,
    },
    currentPod: {
      handler(nue, old) {
        if (JSON.stringify(nue) !== JSON.stringify(old) && nue.name) {
          this.initUsedRate();
          this.initGuestInstalled();
        }
      },
      deep:      true,
      immediate: true,
    },
    isPowerOn: {
      handler(nue) {
        if (!nue) {
          this.cpuUsedRate = 0;
          this.ramUsedRate = 0;
          this.diskUsedRate = 0;
        }
      },
      deep:      true,
      immediate: true,
    },
  },
};
</script>
<template>
  <div class="card">
    <div class="top">
      <div class="actions">
        <i
          :class="value.starClass"
          @click="onActionStar"
        />
        <slot name="checkbox" />
      </div>
      <div class="osImg">
        <img
          :src="osImg"
          alt=""
          width="40px"
          height="40px"
          :onerror="loadImgSrc"
        >
      </div>
      <div class="name">
        <el-tooltip
          :content="alias?alias+'/'+value.metadata.name:value.metadata.name"
          effect="light"
        >
          <div class="nameDiv">
            {{ t('pai.vmsetTmpl.templateName') }}： <a @click="vmDetail">{{ alias ? omitString(alias, 20) : omitString(value.metadata.name, 20) }}</a>
          </div>
        </el-tooltip>
        <div>
          <div class="nameDiv">
            {{ t('pai.vmsetTmpl.imageName') }}： {{ imageAlias ? imageAlias :'-' }}
          </div>
        </div>
        <div>
          <span style="margin-right: 6px;">CPU: {{ cpuData }}</span>
          <span style="margin-right: 6px;">{{ t('pai.vmset.ram') }}: {{ ramData }}</span>
          <span>{{ t('pai.vmset.disk') }}: {{ diskData }}</span>
        </div>
      </div>
    </div>
    <div class="line" />
    <div class="bottom">
      <div
        class="action"
      >
        <div @click="vmAdd">
          <i class="el-icon-document-add" />{{ t('pai.detail.vmset.createVm') }}
        </div>
        <div @click="vmDetail">
          <i class="el-icon-edit" />{{ t('pai.detail.vmset.edit') }}
        </div>
        <div @click="onDelete">
          <i class="el-icon-delete" />{{ t('pai.detail.vmset.delete') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  box-shadow:
      0 1px 1px rgb(0 0 0 / 0.25),
      0 2px 2px rgb(0 0 0 / 0.20),
      0 4px 4px rgb(0 0 0 / 0.15),
      0 8px 8px rgb(0 0 0 / 0.10),
      0 16px 16px rgb(0 0 0 / 0.05);
  width: 260px;
  height: 140px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(239, 239, 239, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  border: 1px solid rgba(206, 206, 206, 1);
  padding: 0 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  white-space: nowrap;

  .top {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 55%;
    .actions {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;

      i {
        cursor: pointer;
        color: #FCCA00;
        margin-right: 1px;
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      overflow: hidden;
      line-height: 22px;
      margin-top: 4px;
      .nameDiv {
        width: 150px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
          cursor: pointer;
        }
      }

      .status {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pod {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }

    .osImg {
      //flex: 0.25;
      display: flex;
      justify-content: center;
      //margin-right: 4px;
      margin: 0px 8px 0px 4px;

      img {
        width: 40px;
      }
    }

    .os {
      flex: 0.3;
      overflow: hidden;
    }
  }

  .line {
    border-bottom: 2px solid #246FA5;
    height: 5%;
  }

  .bottom {
    font-size: 11px;
    text-align: left;
    //height: 55%;
    display: flex;
    align-items: center;
    padding: 8px 0px;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action {
      font-size: 12px;
      width: 100%;
      display: flex;
      justify-content: space-around;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }
      i {
        font-size: 17px;
        color: #246FA5;
        margin-bottom: 2px;
      }
    }
  }
}
</style>
